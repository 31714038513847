/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      id
      surveyOwner
      isUnowned
      surveyName
      entityName
      surveyTitle
      questionText
      createdAt
      numResponses
      freeResponseCreditsRemaining
      responseCreditsConsumed
      largestLengthCategoryAllowed
      sendEmailUpdates
      respondentsCanSeeThemes
      closed
      responses {
        items {
          id
          surveyID
          surveyOwner
          submitterUsername
          state
          duplicatedResponseID
          rawResponse
          processedAt
          length
          lengthCategory
          commentVersion
          sendUpdatesToEmail
          pendingEmailVerificationCode
          emailVerificationAttempts
          respondentReceivingEmailUpdates
          createdAt
          updatedAt
          surveyResponseRespondentKeyId
          __typename
        }
        nextToken
        __typename
      }
      nextAllowedResponsesExportDate
      themes {
        items {
          id
          surveyOwner
          surveyID
          surveyIDHidden
          text
          sentiment
          numOccurrences
          numOccurrencesSinceLastComment
          commentVersion
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hiddenThemes {
        items {
          id
          surveyOwner
          surveyID
          surveyIDHidden
          text
          sentiment
          numOccurrences
          numOccurrencesSinceLastComment
          commentVersion
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hasResponsesSinceLastStats
      statsEntriesDaily {
        items {
          surveyOwner
          surveyIDDaily
          surveyIDWeekly
          date
          label
          numResponses
          numThemeMatchesPos
          numThemeMatchesNeu
          numThemeMatchesNeg
          textSummary
          graphImageUrl
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      statsEntriesWeekly {
        items {
          surveyOwner
          surveyIDDaily
          surveyIDWeekly
          date
          label
          numResponses
          numThemeMatchesPos
          numThemeMatchesNeu
          numThemeMatchesNeg
          textSummary
          graphImageUrl
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shareUrl
      updatedAt
      __typename
    }
  }
`;
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      id
      surveyOwner
      isUnowned
      surveyName
      entityName
      surveyTitle
      questionText
      createdAt
      numResponses
      freeResponseCreditsRemaining
      responseCreditsConsumed
      largestLengthCategoryAllowed
      sendEmailUpdates
      respondentsCanSeeThemes
      closed
      responses {
        items {
          id
          surveyID
          surveyOwner
          submitterUsername
          state
          duplicatedResponseID
          rawResponse
          processedAt
          length
          lengthCategory
          commentVersion
          sendUpdatesToEmail
          pendingEmailVerificationCode
          emailVerificationAttempts
          respondentReceivingEmailUpdates
          createdAt
          updatedAt
          surveyResponseRespondentKeyId
          __typename
        }
        nextToken
        __typename
      }
      nextAllowedResponsesExportDate
      themes {
        items {
          id
          surveyOwner
          surveyID
          surveyIDHidden
          text
          sentiment
          numOccurrences
          numOccurrencesSinceLastComment
          commentVersion
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hiddenThemes {
        items {
          id
          surveyOwner
          surveyID
          surveyIDHidden
          text
          sentiment
          numOccurrences
          numOccurrencesSinceLastComment
          commentVersion
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hasResponsesSinceLastStats
      statsEntriesDaily {
        items {
          surveyOwner
          surveyIDDaily
          surveyIDWeekly
          date
          label
          numResponses
          numThemeMatchesPos
          numThemeMatchesNeu
          numThemeMatchesNeg
          textSummary
          graphImageUrl
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      statsEntriesWeekly {
        items {
          surveyOwner
          surveyIDDaily
          surveyIDWeekly
          date
          label
          numResponses
          numThemeMatchesPos
          numThemeMatchesNeu
          numThemeMatchesNeg
          textSummary
          graphImageUrl
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shareUrl
      updatedAt
      __typename
    }
  }
`;
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      id
      surveyOwner
      isUnowned
      surveyName
      entityName
      surveyTitle
      questionText
      createdAt
      numResponses
      freeResponseCreditsRemaining
      responseCreditsConsumed
      largestLengthCategoryAllowed
      sendEmailUpdates
      respondentsCanSeeThemes
      closed
      responses {
        items {
          id
          surveyID
          surveyOwner
          submitterUsername
          state
          duplicatedResponseID
          rawResponse
          processedAt
          length
          lengthCategory
          commentVersion
          sendUpdatesToEmail
          pendingEmailVerificationCode
          emailVerificationAttempts
          respondentReceivingEmailUpdates
          createdAt
          updatedAt
          surveyResponseRespondentKeyId
          __typename
        }
        nextToken
        __typename
      }
      nextAllowedResponsesExportDate
      themes {
        items {
          id
          surveyOwner
          surveyID
          surveyIDHidden
          text
          sentiment
          numOccurrences
          numOccurrencesSinceLastComment
          commentVersion
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hiddenThemes {
        items {
          id
          surveyOwner
          surveyID
          surveyIDHidden
          text
          sentiment
          numOccurrences
          numOccurrencesSinceLastComment
          commentVersion
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hasResponsesSinceLastStats
      statsEntriesDaily {
        items {
          surveyOwner
          surveyIDDaily
          surveyIDWeekly
          date
          label
          numResponses
          numThemeMatchesPos
          numThemeMatchesNeu
          numThemeMatchesNeg
          textSummary
          graphImageUrl
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      statsEntriesWeekly {
        items {
          surveyOwner
          surveyIDDaily
          surveyIDWeekly
          date
          label
          numResponses
          numThemeMatchesPos
          numThemeMatchesNeu
          numThemeMatchesNeg
          textSummary
          graphImageUrl
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shareUrl
      updatedAt
      __typename
    }
  }
`;
export const createSurveyStatsEntry = /* GraphQL */ `
  mutation CreateSurveyStatsEntry(
    $input: CreateSurveyStatsEntryInput!
    $condition: ModelSurveyStatsEntryConditionInput
  ) {
    createSurveyStatsEntry(input: $input, condition: $condition) {
      surveyOwner
      surveyIDDaily
      surveyIDWeekly
      date
      label
      numResponses
      numThemeMatchesPos
      numThemeMatchesNeu
      numThemeMatchesNeg
      topThemes {
        themeText
        themeSentiment
        numOccurrences
        __typename
      }
      textSummary
      graphImageUrl
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSurveyStatsEntry = /* GraphQL */ `
  mutation UpdateSurveyStatsEntry(
    $input: UpdateSurveyStatsEntryInput!
    $condition: ModelSurveyStatsEntryConditionInput
  ) {
    updateSurveyStatsEntry(input: $input, condition: $condition) {
      surveyOwner
      surveyIDDaily
      surveyIDWeekly
      date
      label
      numResponses
      numThemeMatchesPos
      numThemeMatchesNeu
      numThemeMatchesNeg
      topThemes {
        themeText
        themeSentiment
        numOccurrences
        __typename
      }
      textSummary
      graphImageUrl
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSurveyStatsEntry = /* GraphQL */ `
  mutation DeleteSurveyStatsEntry(
    $input: DeleteSurveyStatsEntryInput!
    $condition: ModelSurveyStatsEntryConditionInput
  ) {
    deleteSurveyStatsEntry(input: $input, condition: $condition) {
      surveyOwner
      surveyIDDaily
      surveyIDWeekly
      date
      label
      numResponses
      numThemeMatchesPos
      numThemeMatchesNeu
      numThemeMatchesNeg
      topThemes {
        themeText
        themeSentiment
        numOccurrences
        __typename
      }
      textSummary
      graphImageUrl
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSimilarityScore = /* GraphQL */ `
  mutation CreateSimilarityScore(
    $input: CreateSimilarityScoreInput!
    $condition: ModelSimilarityScoreConditionInput
  ) {
    createSimilarityScore(input: $input, condition: $condition) {
      pointText
      themeText
      similarityScore
      constFld
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSimilarityScore = /* GraphQL */ `
  mutation UpdateSimilarityScore(
    $input: UpdateSimilarityScoreInput!
    $condition: ModelSimilarityScoreConditionInput
  ) {
    updateSimilarityScore(input: $input, condition: $condition) {
      pointText
      themeText
      similarityScore
      constFld
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSimilarityScore = /* GraphQL */ `
  mutation DeleteSimilarityScore(
    $input: DeleteSimilarityScoreInput!
    $condition: ModelSimilarityScoreConditionInput
  ) {
    deleteSimilarityScore(input: $input, condition: $condition) {
      pointText
      themeText
      similarityScore
      constFld
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSurveyTheme = /* GraphQL */ `
  mutation CreateSurveyTheme(
    $input: CreateSurveyThemeInput!
    $condition: ModelSurveyThemeConditionInput
  ) {
    createSurveyTheme(input: $input, condition: $condition) {
      id
      surveyOwner
      surveyID
      surveyIDHidden
      survey {
        id
        surveyOwner
        isUnowned
        surveyName
        entityName
        surveyTitle
        questionText
        createdAt
        numResponses
        freeResponseCreditsRemaining
        responseCreditsConsumed
        largestLengthCategoryAllowed
        sendEmailUpdates
        respondentsCanSeeThemes
        closed
        responses {
          nextToken
          __typename
        }
        nextAllowedResponsesExportDate
        themes {
          nextToken
          __typename
        }
        hiddenThemes {
          nextToken
          __typename
        }
        hasResponsesSinceLastStats
        statsEntriesDaily {
          nextToken
          __typename
        }
        statsEntriesWeekly {
          nextToken
          __typename
        }
        shareUrl
        updatedAt
        __typename
      }
      text
      sentiment
      numOccurrences
      numOccurrencesSinceLastComment
      embedding {
        vector
        id
        createdAt
        updatedAt
        __typename
      }
      commentVersion
      comments {
        text
        createdAt
        commenter
        __typename
      }
      points {
        items {
          id
          surveyOwner
          createdAt
          surveyResponseID
          themeID
          text
          responseExcerpt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSurveyTheme = /* GraphQL */ `
  mutation UpdateSurveyTheme(
    $input: UpdateSurveyThemeInput!
    $condition: ModelSurveyThemeConditionInput
  ) {
    updateSurveyTheme(input: $input, condition: $condition) {
      id
      surveyOwner
      surveyID
      surveyIDHidden
      survey {
        id
        surveyOwner
        isUnowned
        surveyName
        entityName
        surveyTitle
        questionText
        createdAt
        numResponses
        freeResponseCreditsRemaining
        responseCreditsConsumed
        largestLengthCategoryAllowed
        sendEmailUpdates
        respondentsCanSeeThemes
        closed
        responses {
          nextToken
          __typename
        }
        nextAllowedResponsesExportDate
        themes {
          nextToken
          __typename
        }
        hiddenThemes {
          nextToken
          __typename
        }
        hasResponsesSinceLastStats
        statsEntriesDaily {
          nextToken
          __typename
        }
        statsEntriesWeekly {
          nextToken
          __typename
        }
        shareUrl
        updatedAt
        __typename
      }
      text
      sentiment
      numOccurrences
      numOccurrencesSinceLastComment
      embedding {
        vector
        id
        createdAt
        updatedAt
        __typename
      }
      commentVersion
      comments {
        text
        createdAt
        commenter
        __typename
      }
      points {
        items {
          id
          surveyOwner
          createdAt
          surveyResponseID
          themeID
          text
          responseExcerpt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSurveyTheme = /* GraphQL */ `
  mutation DeleteSurveyTheme(
    $input: DeleteSurveyThemeInput!
    $condition: ModelSurveyThemeConditionInput
  ) {
    deleteSurveyTheme(input: $input, condition: $condition) {
      id
      surveyOwner
      surveyID
      surveyIDHidden
      survey {
        id
        surveyOwner
        isUnowned
        surveyName
        entityName
        surveyTitle
        questionText
        createdAt
        numResponses
        freeResponseCreditsRemaining
        responseCreditsConsumed
        largestLengthCategoryAllowed
        sendEmailUpdates
        respondentsCanSeeThemes
        closed
        responses {
          nextToken
          __typename
        }
        nextAllowedResponsesExportDate
        themes {
          nextToken
          __typename
        }
        hiddenThemes {
          nextToken
          __typename
        }
        hasResponsesSinceLastStats
        statsEntriesDaily {
          nextToken
          __typename
        }
        statsEntriesWeekly {
          nextToken
          __typename
        }
        shareUrl
        updatedAt
        __typename
      }
      text
      sentiment
      numOccurrences
      numOccurrencesSinceLastComment
      embedding {
        vector
        id
        createdAt
        updatedAt
        __typename
      }
      commentVersion
      comments {
        text
        createdAt
        commenter
        __typename
      }
      points {
        items {
          id
          surveyOwner
          createdAt
          surveyResponseID
          themeID
          text
          responseExcerpt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSurveyThemeEmbedding = /* GraphQL */ `
  mutation CreateSurveyThemeEmbedding(
    $input: CreateSurveyThemeEmbeddingInput!
    $condition: ModelSurveyThemeEmbeddingConditionInput
  ) {
    createSurveyThemeEmbedding(input: $input, condition: $condition) {
      vector
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSurveyThemeEmbedding = /* GraphQL */ `
  mutation UpdateSurveyThemeEmbedding(
    $input: UpdateSurveyThemeEmbeddingInput!
    $condition: ModelSurveyThemeEmbeddingConditionInput
  ) {
    updateSurveyThemeEmbedding(input: $input, condition: $condition) {
      vector
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSurveyThemeEmbedding = /* GraphQL */ `
  mutation DeleteSurveyThemeEmbedding(
    $input: DeleteSurveyThemeEmbeddingInput!
    $condition: ModelSurveyThemeEmbeddingConditionInput
  ) {
    deleteSurveyThemeEmbedding(input: $input, condition: $condition) {
      vector
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlreadySubmittedResponse = /* GraphQL */ `
  mutation CreateAlreadySubmittedResponse(
    $input: CreateAlreadySubmittedResponseInput!
    $condition: ModelAlreadySubmittedResponseConditionInput
  ) {
    createAlreadySubmittedResponse(input: $input, condition: $condition) {
      surveyID
      submitterUsername
      responseID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlreadySubmittedResponse = /* GraphQL */ `
  mutation UpdateAlreadySubmittedResponse(
    $input: UpdateAlreadySubmittedResponseInput!
    $condition: ModelAlreadySubmittedResponseConditionInput
  ) {
    updateAlreadySubmittedResponse(input: $input, condition: $condition) {
      surveyID
      submitterUsername
      responseID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlreadySubmittedResponse = /* GraphQL */ `
  mutation DeleteAlreadySubmittedResponse(
    $input: DeleteAlreadySubmittedResponseInput!
    $condition: ModelAlreadySubmittedResponseConditionInput
  ) {
    deleteAlreadySubmittedResponse(input: $input, condition: $condition) {
      surveyID
      submitterUsername
      responseID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSurveyResponseRespondentKey = /* GraphQL */ `
  mutation CreateSurveyResponseRespondentKey(
    $input: CreateSurveyResponseRespondentKeyInput!
    $condition: ModelSurveyResponseRespondentKeyConditionInput
  ) {
    createSurveyResponseRespondentKey(input: $input, condition: $condition) {
      response {
        id
        surveyID
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        surveyOwner
        respondentKey {
          id
          createdAt
          updatedAt
          surveyResponseRespondentKeyResponseId
          __typename
        }
        submitterUsername
        state
        duplicatedResponseID
        rawResponse
        points {
          nextToken
          __typename
        }
        processedAt
        length
        lengthCategory
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        sendUpdatesToEmail
        pendingEmailVerificationCode
        emailVerificationAttempts
        respondentReceivingEmailUpdates
        themeDenorm {
          id
          text
          sentiment
          __typename
        }
        createdAt
        updatedAt
        surveyResponseRespondentKeyId
        __typename
      }
      id
      createdAt
      updatedAt
      surveyResponseRespondentKeyResponseId
      __typename
    }
  }
`;
export const updateSurveyResponseRespondentKey = /* GraphQL */ `
  mutation UpdateSurveyResponseRespondentKey(
    $input: UpdateSurveyResponseRespondentKeyInput!
    $condition: ModelSurveyResponseRespondentKeyConditionInput
  ) {
    updateSurveyResponseRespondentKey(input: $input, condition: $condition) {
      response {
        id
        surveyID
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        surveyOwner
        respondentKey {
          id
          createdAt
          updatedAt
          surveyResponseRespondentKeyResponseId
          __typename
        }
        submitterUsername
        state
        duplicatedResponseID
        rawResponse
        points {
          nextToken
          __typename
        }
        processedAt
        length
        lengthCategory
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        sendUpdatesToEmail
        pendingEmailVerificationCode
        emailVerificationAttempts
        respondentReceivingEmailUpdates
        themeDenorm {
          id
          text
          sentiment
          __typename
        }
        createdAt
        updatedAt
        surveyResponseRespondentKeyId
        __typename
      }
      id
      createdAt
      updatedAt
      surveyResponseRespondentKeyResponseId
      __typename
    }
  }
`;
export const deleteSurveyResponseRespondentKey = /* GraphQL */ `
  mutation DeleteSurveyResponseRespondentKey(
    $input: DeleteSurveyResponseRespondentKeyInput!
    $condition: ModelSurveyResponseRespondentKeyConditionInput
  ) {
    deleteSurveyResponseRespondentKey(input: $input, condition: $condition) {
      response {
        id
        surveyID
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        surveyOwner
        respondentKey {
          id
          createdAt
          updatedAt
          surveyResponseRespondentKeyResponseId
          __typename
        }
        submitterUsername
        state
        duplicatedResponseID
        rawResponse
        points {
          nextToken
          __typename
        }
        processedAt
        length
        lengthCategory
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        sendUpdatesToEmail
        pendingEmailVerificationCode
        emailVerificationAttempts
        respondentReceivingEmailUpdates
        themeDenorm {
          id
          text
          sentiment
          __typename
        }
        createdAt
        updatedAt
        surveyResponseRespondentKeyId
        __typename
      }
      id
      createdAt
      updatedAt
      surveyResponseRespondentKeyResponseId
      __typename
    }
  }
`;
export const createSurveyResponse = /* GraphQL */ `
  mutation CreateSurveyResponse(
    $input: CreateSurveyResponseInput!
    $condition: ModelSurveyResponseConditionInput
  ) {
    createSurveyResponse(input: $input, condition: $condition) {
      id
      surveyID
      survey {
        id
        surveyOwner
        isUnowned
        surveyName
        entityName
        surveyTitle
        questionText
        createdAt
        numResponses
        freeResponseCreditsRemaining
        responseCreditsConsumed
        largestLengthCategoryAllowed
        sendEmailUpdates
        respondentsCanSeeThemes
        closed
        responses {
          nextToken
          __typename
        }
        nextAllowedResponsesExportDate
        themes {
          nextToken
          __typename
        }
        hiddenThemes {
          nextToken
          __typename
        }
        hasResponsesSinceLastStats
        statsEntriesDaily {
          nextToken
          __typename
        }
        statsEntriesWeekly {
          nextToken
          __typename
        }
        shareUrl
        updatedAt
        __typename
      }
      surveyOwner
      respondentKey {
        response {
          id
          surveyID
          surveyOwner
          submitterUsername
          state
          duplicatedResponseID
          rawResponse
          processedAt
          length
          lengthCategory
          commentVersion
          sendUpdatesToEmail
          pendingEmailVerificationCode
          emailVerificationAttempts
          respondentReceivingEmailUpdates
          createdAt
          updatedAt
          surveyResponseRespondentKeyId
          __typename
        }
        id
        createdAt
        updatedAt
        surveyResponseRespondentKeyResponseId
        __typename
      }
      submitterUsername
      state
      duplicatedResponseID
      rawResponse
      points {
        items {
          id
          surveyOwner
          createdAt
          surveyResponseID
          themeID
          text
          responseExcerpt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      processedAt
      length
      lengthCategory
      commentVersion
      comments {
        text
        createdAt
        commenter
        __typename
      }
      sendUpdatesToEmail
      pendingEmailVerificationCode
      emailVerificationAttempts
      respondentReceivingEmailUpdates
      themeDenorm {
        id
        text
        sentiment
        __typename
      }
      createdAt
      updatedAt
      surveyResponseRespondentKeyId
      __typename
    }
  }
`;
export const updateSurveyResponse = /* GraphQL */ `
  mutation UpdateSurveyResponse(
    $input: UpdateSurveyResponseInput!
    $condition: ModelSurveyResponseConditionInput
  ) {
    updateSurveyResponse(input: $input, condition: $condition) {
      id
      surveyID
      survey {
        id
        surveyOwner
        isUnowned
        surveyName
        entityName
        surveyTitle
        questionText
        createdAt
        numResponses
        freeResponseCreditsRemaining
        responseCreditsConsumed
        largestLengthCategoryAllowed
        sendEmailUpdates
        respondentsCanSeeThemes
        closed
        responses {
          nextToken
          __typename
        }
        nextAllowedResponsesExportDate
        themes {
          nextToken
          __typename
        }
        hiddenThemes {
          nextToken
          __typename
        }
        hasResponsesSinceLastStats
        statsEntriesDaily {
          nextToken
          __typename
        }
        statsEntriesWeekly {
          nextToken
          __typename
        }
        shareUrl
        updatedAt
        __typename
      }
      surveyOwner
      respondentKey {
        response {
          id
          surveyID
          surveyOwner
          submitterUsername
          state
          duplicatedResponseID
          rawResponse
          processedAt
          length
          lengthCategory
          commentVersion
          sendUpdatesToEmail
          pendingEmailVerificationCode
          emailVerificationAttempts
          respondentReceivingEmailUpdates
          createdAt
          updatedAt
          surveyResponseRespondentKeyId
          __typename
        }
        id
        createdAt
        updatedAt
        surveyResponseRespondentKeyResponseId
        __typename
      }
      submitterUsername
      state
      duplicatedResponseID
      rawResponse
      points {
        items {
          id
          surveyOwner
          createdAt
          surveyResponseID
          themeID
          text
          responseExcerpt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      processedAt
      length
      lengthCategory
      commentVersion
      comments {
        text
        createdAt
        commenter
        __typename
      }
      sendUpdatesToEmail
      pendingEmailVerificationCode
      emailVerificationAttempts
      respondentReceivingEmailUpdates
      themeDenorm {
        id
        text
        sentiment
        __typename
      }
      createdAt
      updatedAt
      surveyResponseRespondentKeyId
      __typename
    }
  }
`;
export const deleteSurveyResponse = /* GraphQL */ `
  mutation DeleteSurveyResponse(
    $input: DeleteSurveyResponseInput!
    $condition: ModelSurveyResponseConditionInput
  ) {
    deleteSurveyResponse(input: $input, condition: $condition) {
      id
      surveyID
      survey {
        id
        surveyOwner
        isUnowned
        surveyName
        entityName
        surveyTitle
        questionText
        createdAt
        numResponses
        freeResponseCreditsRemaining
        responseCreditsConsumed
        largestLengthCategoryAllowed
        sendEmailUpdates
        respondentsCanSeeThemes
        closed
        responses {
          nextToken
          __typename
        }
        nextAllowedResponsesExportDate
        themes {
          nextToken
          __typename
        }
        hiddenThemes {
          nextToken
          __typename
        }
        hasResponsesSinceLastStats
        statsEntriesDaily {
          nextToken
          __typename
        }
        statsEntriesWeekly {
          nextToken
          __typename
        }
        shareUrl
        updatedAt
        __typename
      }
      surveyOwner
      respondentKey {
        response {
          id
          surveyID
          surveyOwner
          submitterUsername
          state
          duplicatedResponseID
          rawResponse
          processedAt
          length
          lengthCategory
          commentVersion
          sendUpdatesToEmail
          pendingEmailVerificationCode
          emailVerificationAttempts
          respondentReceivingEmailUpdates
          createdAt
          updatedAt
          surveyResponseRespondentKeyId
          __typename
        }
        id
        createdAt
        updatedAt
        surveyResponseRespondentKeyResponseId
        __typename
      }
      submitterUsername
      state
      duplicatedResponseID
      rawResponse
      points {
        items {
          id
          surveyOwner
          createdAt
          surveyResponseID
          themeID
          text
          responseExcerpt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      processedAt
      length
      lengthCategory
      commentVersion
      comments {
        text
        createdAt
        commenter
        __typename
      }
      sendUpdatesToEmail
      pendingEmailVerificationCode
      emailVerificationAttempts
      respondentReceivingEmailUpdates
      themeDenorm {
        id
        text
        sentiment
        __typename
      }
      createdAt
      updatedAt
      surveyResponseRespondentKeyId
      __typename
    }
  }
`;
export const createSurveyResponsePoint = /* GraphQL */ `
  mutation CreateSurveyResponsePoint(
    $input: CreateSurveyResponsePointInput!
    $condition: ModelSurveyResponsePointConditionInput
  ) {
    createSurveyResponsePoint(input: $input, condition: $condition) {
      id
      surveyOwner
      createdAt
      surveyResponseID
      surveyResponse {
        id
        surveyID
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        surveyOwner
        respondentKey {
          id
          createdAt
          updatedAt
          surveyResponseRespondentKeyResponseId
          __typename
        }
        submitterUsername
        state
        duplicatedResponseID
        rawResponse
        points {
          nextToken
          __typename
        }
        processedAt
        length
        lengthCategory
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        sendUpdatesToEmail
        pendingEmailVerificationCode
        emailVerificationAttempts
        respondentReceivingEmailUpdates
        themeDenorm {
          id
          text
          sentiment
          __typename
        }
        createdAt
        updatedAt
        surveyResponseRespondentKeyId
        __typename
      }
      themeID
      theme {
        id
        surveyOwner
        surveyID
        surveyIDHidden
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        text
        sentiment
        numOccurrences
        numOccurrencesSinceLastComment
        embedding {
          vector
          id
          createdAt
          updatedAt
          __typename
        }
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        points {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      text
      responseExcerpt
      updatedAt
      __typename
    }
  }
`;
export const updateSurveyResponsePoint = /* GraphQL */ `
  mutation UpdateSurveyResponsePoint(
    $input: UpdateSurveyResponsePointInput!
    $condition: ModelSurveyResponsePointConditionInput
  ) {
    updateSurveyResponsePoint(input: $input, condition: $condition) {
      id
      surveyOwner
      createdAt
      surveyResponseID
      surveyResponse {
        id
        surveyID
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        surveyOwner
        respondentKey {
          id
          createdAt
          updatedAt
          surveyResponseRespondentKeyResponseId
          __typename
        }
        submitterUsername
        state
        duplicatedResponseID
        rawResponse
        points {
          nextToken
          __typename
        }
        processedAt
        length
        lengthCategory
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        sendUpdatesToEmail
        pendingEmailVerificationCode
        emailVerificationAttempts
        respondentReceivingEmailUpdates
        themeDenorm {
          id
          text
          sentiment
          __typename
        }
        createdAt
        updatedAt
        surveyResponseRespondentKeyId
        __typename
      }
      themeID
      theme {
        id
        surveyOwner
        surveyID
        surveyIDHidden
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        text
        sentiment
        numOccurrences
        numOccurrencesSinceLastComment
        embedding {
          vector
          id
          createdAt
          updatedAt
          __typename
        }
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        points {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      text
      responseExcerpt
      updatedAt
      __typename
    }
  }
`;
export const deleteSurveyResponsePoint = /* GraphQL */ `
  mutation DeleteSurveyResponsePoint(
    $input: DeleteSurveyResponsePointInput!
    $condition: ModelSurveyResponsePointConditionInput
  ) {
    deleteSurveyResponsePoint(input: $input, condition: $condition) {
      id
      surveyOwner
      createdAt
      surveyResponseID
      surveyResponse {
        id
        surveyID
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        surveyOwner
        respondentKey {
          id
          createdAt
          updatedAt
          surveyResponseRespondentKeyResponseId
          __typename
        }
        submitterUsername
        state
        duplicatedResponseID
        rawResponse
        points {
          nextToken
          __typename
        }
        processedAt
        length
        lengthCategory
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        sendUpdatesToEmail
        pendingEmailVerificationCode
        emailVerificationAttempts
        respondentReceivingEmailUpdates
        themeDenorm {
          id
          text
          sentiment
          __typename
        }
        createdAt
        updatedAt
        surveyResponseRespondentKeyId
        __typename
      }
      themeID
      theme {
        id
        surveyOwner
        surveyID
        surveyIDHidden
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        text
        sentiment
        numOccurrences
        numOccurrencesSinceLastComment
        embedding {
          vector
          id
          createdAt
          updatedAt
          __typename
        }
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        points {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      text
      responseExcerpt
      updatedAt
      __typename
    }
  }
`;
export const createSurveyObj = /* GraphQL */ `
  mutation CreateSurveyObj(
    $details: SurveyCreateDetails!
    $recaptchaToken: String!
  ) {
    createSurveyObj(details: $details, recaptchaToken: $recaptchaToken)
  }
`;
export const submitResponseMessage = /* GraphQL */ `
  mutation SubmitResponseMessage(
    $surveyId: ID!
    $responseMessage: String!
    $recaptchaToken: String!
    $sendUpdatesToEmail: String
  ) {
    submitResponseMessage(
      surveyId: $surveyId
      responseMessage: $responseMessage
      recaptchaToken: $recaptchaToken
      sendUpdatesToEmail: $sendUpdatesToEmail
    )
  }
`;
export const verifyResponseNotificationsEmailAddress = /* GraphQL */ `
  mutation VerifyResponseNotificationsEmailAddress(
    $responseRespondentKeyId: ID!
    $verificationCode: Int!
  ) {
    verifyResponseNotificationsEmailAddress(
      responseRespondentKeyId: $responseRespondentKeyId
      verificationCode: $verificationCode
    )
  }
`;
export const updateResponseNotificationsEmailAddress = /* GraphQL */ `
  mutation UpdateResponseNotificationsEmailAddress(
    $responseRespondentKeyId: ID!
    $newEmailAddress: String
  ) {
    updateResponseNotificationsEmailAddress(
      responseRespondentKeyId: $responseRespondentKeyId
      newEmailAddress: $newEmailAddress
    )
  }
`;
export const resendResponseNotificationsEmailAddressVerificationCode = /* GraphQL */ `
  mutation ResendResponseNotificationsEmailAddressVerificationCode(
    $responseRespondentKeyId: ID!
  ) {
    resendResponseNotificationsEmailAddressVerificationCode(
      responseRespondentKeyId: $responseRespondentKeyId
    )
  }
`;
export const addPublisherCommentOnResponse = /* GraphQL */ `
  mutation AddPublisherCommentOnResponse(
    $responseId: ID!
    $comment: String!
    $commentVersion: Int!
  ) {
    addPublisherCommentOnResponse(
      responseId: $responseId
      comment: $comment
      commentVersion: $commentVersion
    ) {
      wasSuccessful
      commentVersion
      comments {
        text
        createdAt
        commenter
        __typename
      }
      __typename
    }
  }
`;
export const addRespondentCommentOnResponse = /* GraphQL */ `
  mutation AddRespondentCommentOnResponse(
    $responseRespondentKeyId: ID!
    $comment: String!
    $commentVersion: Int!
  ) {
    addRespondentCommentOnResponse(
      responseRespondentKeyId: $responseRespondentKeyId
      comment: $comment
      commentVersion: $commentVersion
    ) {
      wasSuccessful
      commentVersion
      comments {
        text
        createdAt
        commenter
        __typename
      }
      __typename
    }
  }
`;
export const deleteLastPublisherCommentOnResponse = /* GraphQL */ `
  mutation DeleteLastPublisherCommentOnResponse(
    $responseId: ID!
    $commentVersion: Int!
  ) {
    deleteLastPublisherCommentOnResponse(
      responseId: $responseId
      commentVersion: $commentVersion
    ) {
      wasSuccessful
      commentVersion
      comments {
        text
        createdAt
        commenter
        __typename
      }
      __typename
    }
  }
`;
export const deleteLastRespondentCommentOnResponse = /* GraphQL */ `
  mutation DeleteLastRespondentCommentOnResponse(
    $responseRespondentKeyId: ID!
    $commentVersion: Int!
  ) {
    deleteLastRespondentCommentOnResponse(
      responseRespondentKeyId: $responseRespondentKeyId
      commentVersion: $commentVersion
    ) {
      wasSuccessful
      commentVersion
      comments {
        text
        createdAt
        commenter
        __typename
      }
      __typename
    }
  }
`;
export const getOrCreateCurrentUserSettings = /* GraphQL */ `
  mutation GetOrCreateCurrentUserSettings($includePurchaseHistory: Boolean!) {
    getOrCreateCurrentUserSettings(
      includePurchaseHistory: $includePurchaseHistory
    ) {
      isNewlyCreated
      responseCreditsAvailable
      lowResponseCreditsWarning
      purchaseHistory {
        purchaseDate
        numCredits
        purchaseAmount
        currency
        __typename
      }
      __typename
    }
  }
`;
export const takeOwnershipOfSurvey = /* GraphQL */ `
  mutation TakeOwnershipOfSurvey($surveyId: ID!) {
    takeOwnershipOfSurvey(surveyId: $surveyId) {
      success
      note
      __typename
    }
  }
`;
export const generateQRCode = /* GraphQL */ `
  mutation GenerateQRCode($surveyId: ID!) {
    generateQRCode(surveyId: $surveyId)
  }
`;
export const generateFlyer = /* GraphQL */ `
  mutation GenerateFlyer($surveyId: ID!, $includeQuestionText: Boolean!) {
    generateFlyer(
      surveyId: $surveyId
      includeQuestionText: $includeQuestionText
    )
  }
`;
