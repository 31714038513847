import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';

import { getPublicSurveyDetails, getPublicSurveyResponseState } from '../../graphql/queries'
import { submitResponseMessage } from '../../graphql/mutations'
import { Container, Row, Col, Form, Alert } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { graphqlQueryAllowingBothLoginStates } from '../../utils';

import './Survey.css';

const POLL_INITIAL_DELAY_MS = 2000;
const POLL_BACKOFF_FACTOR = 1.5;

const State = {
  NOT_SUBMITTED: 'not_submitted',
  SUBMITTING: 'submitting',
  PROCESSING: 'processing'
};


function PublicSurvey() {
  const { surveyId } = useParams();
  const [state, setState] = useState(State.NOT_SUBMITTED);
  const [error, setError] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [showNotificationControls, setShowNotificationControls] = useState(false);
  const [responseData, setResponseData] = useState({
    responseText: '',
    sendUpdatesToEmail: ''
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();

  // returns true if we should continue polling for state updates
  const fetchResponseState = useCallback(async (surveyResponseRespondentKeyId) => {
    try {
      const stateResponse = await graphqlQueryAllowingBothLoginStates(
          getPublicSurveyResponseState,
          { responseRespondentKeyId: surveyResponseRespondentKeyId });
      const stateObj = stateResponse.data.getPublicSurveyResponseState;
    
      console.log("poll received response state:", stateObj.state);
      switch (stateObj.state) {
        case 'UNPROCESSED':
          setState(State.PROCESSING);
          return true;
        case 'NOT_VALID':
          setState(State.NOT_SUBMITTED);
          setError("Hmm, our system didn't accept that submission. Could you revise it and try again?");
          return false;
        case 'VALIDATED':
          setState(State.PROCESSING);
          navigate(`/s/r/${surveyResponseRespondentKeyId}`);
          return false;
        case 'PROCESSED':
          setState(State.PROCESSING);
          navigate(`/s/r/${surveyResponseRespondentKeyId}`);
          return false;
        case 'DUPLICATE':
          navigate(`/s/r/${stateObj.duplicatedResponseRespondentKeyID}`);
          return false;
        case 'UNKNOWN_ERROR':
          setState(State.NOT_SUBMITTED);
          setError("Something went wrong. Please try again.");
          return false;
        default:
          console.log('unexpected state:', stateObj);
          return false;
      }
    } catch (err) {
      console.log('error fetching response', err);
      setError('Error fetching response');
      return false;
    }
  }, [navigate]);

  const startPolling = useCallback(async (surveyResponseRespondentKeyId) => {
    var delay = POLL_INITIAL_DELAY_MS;
    const pollWithBackoff = async () => {
      if (await fetchResponseState(surveyResponseRespondentKeyId)) {
        delay *= POLL_BACKOFF_FACTOR;
        setTimeout(pollWithBackoff, delay);
      }
    };
    pollWithBackoff();
  }, [fetchResponseState]);

  useEffect(() => {
    async function fetchSurvey() {
      try {
        const surveyData = await graphqlQueryAllowingBothLoginStates(
          getPublicSurveyDetails,
          { surveyId: surveyId });
        const survey = surveyData.data.getPublicSurveyDetails;
        if (survey.alreadySubmittedResponseRespondentKeyId) {
          // for testing:
          // console.log("skipping already submitted redirect")
          navigate(`/s/r/${survey.alreadySubmittedResponseRespondentKeyId}`);
          return;
        }
        setSurvey(survey);

        document.title = `${survey.surveyTitle} - ActionaBull`
      } catch (err) {
        console.log('error fetching survey', err)
        setError('Error fetching survey');
        setSurvey(null);
      }
    }
    fetchSurvey();
  }, [surveyId, navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setResponseData({ ...responseData, [name]: value });
  };

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    if (state !== State.NOT_SUBMITTED) {
      return; // Prevent multiple submissions while waiting for response
    }

    setState(State.SUBMITTING);
    setError(null);

    try {
      const trimmedResponseText = responseData.responseText.trim();
      if (trimmedResponseText.length <  3) {
        setError("Your repsonse is too short.");
        setState(State.NOT_SUBMITTED);
        return;
      }
      const recaptchaToken = await executeRecaptcha('submit');
      const response = await graphqlQueryAllowingBothLoginStates(
        submitResponseMessage,
        { surveyId: surveyId,
          responseMessage: responseData.responseText,
          recaptchaToken: recaptchaToken,
          sendUpdatesToEmail: responseData.sendUpdatesToEmail?.trim() || null });
      setState(State.PROCESSING);
      startPolling(response.data.submitResponseMessage);
    } catch (err) {
      console.log('error creating survey', err)
      setError(err.errors[0].message)
      setState(State.NOT_SUBMITTED);
    }
  }, [executeRecaptcha, responseData.responseText, responseData.sendUpdatesToEmail, startPolling, state, surveyId]);

  if (!survey) {
    if (error) {
      return (
        <div className="m-5">Error loading survey: {error}</div>
      );
    }
    return (
      <div className="m-5">Loading...</div>
    );
  }

  return (
    <>
      <Container className=''>
        <Form onSubmit={handleSubmit}>
          <Row className="my-3 align-items-center">
            <Col className="col-12">
              {survey.isCurrentUserOwner && (
                <Alert variant="warning">
                  <Alert.Heading>Preview</Alert.Heading>
                  This is a preview of how your survey will look to respondents.
                </Alert>
              )}
            </Col>
          </Row>

          {survey.closed ? (
            <Row className="ma-3 align-items-center">
              <Col className="col-12">
                Thank you for visiting. This survey is now closed.
              </Col>
            </Row>
           ) : (!survey.publisherHasCredits || survey.isUnowned) ? (
            <Row className="ma-3 align-items-center">
              <Col className="col-12">
              This survey is not currently active.
              </Col>
            </Row>
           ) : (
            <>
              <Row className="ma-3 align-items-center">
                <Col className="col-12">
                  <h4>{survey.surveyTitle}</h4>
                </Col>
              </Row>
              <Row className="my-3 align-items-center">
                <Col className="col-12 mb-2">
                  {survey.questionText.split('\n').map((item, key) => {
                      return <span key={key}>{item}<br /></span>
                    })}
                </Col>
              </Row>
              <Row className="mt-3 ">
                <Col className="col-12 mb-0 text-end">
                  <Form.Group className='my-0'>
                    <textarea
                      className="form-control"
                      id="responseText"
                      name="responseText"
                      placeholder="Add your input here."
                      value={responseData.responseText}
                      onChange={handleChange}
                      required
                      disabled={state !== State.NOT_SUBMITTED}
                      maxLength={survey.responseSizeLimit}
                      rows="5"
                    />
                    <Form.Text className=''>
                      {responseData.responseText.length}/{survey.responseSizeLimit} chars used
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col className="mb-3 col-12">
                  <Form.Group className='my-0'>
                    <Form.Check
                      type="checkbox"
                      id="showNotificationControls"
                      name="showNotificationControls"
                      label="Notify me about changes"
                      checked={showNotificationControls}
                      onChange={(e) => setShowNotificationControls(e.target.checked)}
                      title="Select to get notified when there are changes to this survey."
                    />
                    {showNotificationControls && (
                      <div className="my-2">
                        <Form.Control
                          type="text"
                          id="sendUpdatesToEmail"
                          name="sendUpdatesToEmail"
                          placeholder="Your email"
                          value={responseData.sendUpdatesToEmail}
                          onChange={handleChange}
                          maxLength="200"
                          size="sm"
                          style={{ width: '40%', display: 'inline' }}
                        />
                        <Form.Text muted className='ms-1'>Email is not shared with publisher.</Form.Text>
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col className="mb-5 col-12">
                  <Form.Group>
                    <button type="submit" className="btn btn-primary" disabled={!executeRecaptcha || state !== State.NOT_SUBMITTED || survey.isCurrentUserOwner}>
                      {!executeRecaptcha ? "(loading...)" :
                        state === State.NOT_SUBMITTED ? "Submit" :
                        state === State.SUBMITTING ? "Submitting..." :
                        state === State.PROCESSING ? "Processing..." : "Submit"}
                    </button>
                  </Form.Group>
                </Col>
              </Row>
            </>
           )}


          {error && (
            <Row className="my-3 align-items-center">
              <Col className="col-12 mb-2">
                  <Alert variant='danger'>{error}</Alert>
              </Col>
            </Row>
          )}
          
        </Form>
      </Container>
      <br/>
      <footer style={{ backgroundColor: 'black', fontSize: 'small', color: '#aaa', textAlign: 'center', padding: '.6rem', position: 'fixed', bottom: '0', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <div><a href="https://actionabull.com">ActionaBull</a> &copy; ReadyRickshaw, LLC</div>
        <div><Link to={'/privacy'}>privacy</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/terms'}>terms</Link></div>
      </footer>

    </>
  );
}

export default PublicSurvey;
