import React, { useState, useEffect } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getOrCreateCurrentUserSettingsImpl, isCurrentUserAuthenticated } from '../utils';

function LowResponseCreditsAlert() {

  const [userSettings, setUserSettings] = useState(null);

  async function fetchUserSettings() {
    if (await isCurrentUserAuthenticated()) {
      try {
        setUserSettings(await getOrCreateCurrentUserSettingsImpl(false));
      } catch (err) {
        console.log('error fetch userSettings (lrca):', err);
      }
    }
  }

  useEffect(() => {
    fetchUserSettings();
  }, []);

  function getLowResponseCreditsAlert() {
    if (userSettings == null) {
      return null;
    } else if (userSettings.responseCreditsAvailable < 3) {
      return <Alert variant="danger">
        <Alert.Heading>Insufficient credits remaining.</Alert.Heading>
        Surveys may not accept new responses.&nbsp;
        <Link to="/a/account">
          <Button size="sm" variant="secondary">Buy more credits</Button>
        </Link>
      </Alert>;
    } else if (userSettings.lowResponseCreditsWarning) {
      return <Alert variant="warning">
        <Alert.Heading>You have {userSettings.responseCreditsAvailable} credit{userSettings.responseCreditsAvailable > 1 ? "s" : ""} remaining.</Alert.Heading>
        Surveys will soon stop accepting responses.&nbsp;
        <Link to="/a/account">
            <Button size="sm" variant="secondary">Buy more credits</Button>
        </Link>
      </Alert>;
    } else {
      return null;
    }
  }

  return getLowResponseCreditsAlert();

}

export default LowResponseCreditsAlert;
