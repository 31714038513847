import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { Heading, Text } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';

function TermsOfService() {
  return (
    <div>
      <Navbar bg="light" className="px-3" expand="md">
        <Navbar.Brand href="/">ActionaBull<sup><small style={{ color: '#999999' }}>(beta)</small></sup> <span style={{ position: 'relative', top: '-1px' }}>🐂</span></Navbar.Brand>
      </Navbar>
      <Container className="my-5">
        <Heading level={1}>Terms of Service</Heading>

        <Text className='my-4'>
          Effective Date: July 31, 2023
        </Text>
        <Text className='my-4'>
          This document contains the Terms of Service ("Terms") for using ActionaBull ("Site"), the online survey platform provided by ReadyRickshaw, LLC. By using the Site, you ('User', 'Publisher', 'Respondent', 'You') agree to these Terms.
        </Text>

        <Heading level={2}>
          Terms Of Acceptance
        </Heading>
        <Text className='my-4'>
          It is important that you read all the terms and conditions carefully. By using ActionaBull, either as a publisher generating surveys or as a respondent providing feedback, you signify your agreement to these Terms.
        </Text>

        <Heading level={2}>
          Description Of Service
        </Heading>
        <Text className='my-4'>
          ActionaBull is an online survey platform where Publishers can create surveys and collect feedback from respondents. The Site allows the Publishers to engage with the respondents by commenting on the themes and individual responses.
        </Text>

        <Heading level={2}>
          User Conduct
        </Heading>
        <Text className='my-4'>
          As a user of the Site, you agree:
          <ol>
            <li>to use the Site only for lawful purposes.</li>
            <li>not to provide false or misleading information about yourself or your association with any person or entity.</li>
            <li>not to infringe on the rights of others.</li>
            <li>not to use the Site for any form of spam, unsolicited communication, or other unwanted publicity.</li>
            <li>not to use the Site in any way that could harm the Site or its general operation.</li>
          </ol>
        </Text>

        <Heading level={2}>
          Privacy
        </Heading>
        <Text className='my-4'>
          Your privacy is a priority at ReadyRickshaw, LLC. Please refer to our Privacy Policy for information on how the Site collects, uses, and discloses information about its users.
        </Text>

        <Heading level={2}>
          Intellectual Property
        </Heading>
        <Text className='my-4'>
          You acknowledge that the Site and its original content, features, and functionality are owned by ReadyRickshaw, LLC, and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
        </Text>

        <Heading level={2}>
          Disclaimer
        </Heading>
        <Text className='my-4'>
          The Site is provided "as is." We make no guarantee of the accuracy or completeness of any information provided on the Site.
        </Text>

        <Heading level={2}>
          Liability
        </Heading>
        <Text className='my-4'>
          In no event will ReadyRickshaw, LLC, its directors, employees, or partners be liable for any indirect, consequential, or incidental damages arising out of, or in connection with your use of or inability to use the Site.
        </Text>

        <Heading level={2}>
          Modification
        </Heading>
        <Text className='my-4'>
        ReadyRickshaw, LLC, reserves the right to revise these Terms at any time without providing notice.
        </Text>

        <Heading level={2}>
          Governing Law
        </Heading>
        <Text className='my-4'>
          These Terms shall be governed by and construed in accordance with the laws of the State of California,
          notwithstanding the actual state or country of residence or location of the User. You agree to submit
          to the personal jurisdiction of the state and federal courts located in California for any actions for
          which we retain the right to seek injunctive or other equitable relief in a court of competent
          jurisdiction. ReadyRickshaw LLC is a Delaware limited liability company with its principal place
          of business in California.
        </Text>

        <Heading level={2}>
          Contact Us
        </Heading>
        <Text className='my-4'>
          If you have any questions about these Terms, do not hesitate to contact us at contact@readyrickshaw.com.
          By continuing on this Site, you agree to abide by these terms.
        </Text>
      </Container>
      <footer style={{ backgroundColor: 'black', fontSize: 'small', color: '#aaa', textAlign: 'center', padding: '.6rem', position: 'fixed', bottom: '0', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <div><a href="https://actionabull.com">ActionaBull</a> &copy; ReadyRickshaw, LLC</div>
        <div><Link to={'/privacy'}>privacy</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/terms'}>terms</Link></div>
      </footer>
    </div>
  );
}

export default TermsOfService;
