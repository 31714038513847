import { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, ListGroup, Accordion, Stack, Spinner, Badge, Alert, Form, Button, ButtonGroup } from 'react-bootstrap';
import { getPublicSurveyDetails, getPublicSurveyResponseDetails, getPublicSurveyThemes } from '../../graphql/queries'
import { verifyResponseNotificationsEmailAddress, resendResponseNotificationsEmailAddressVerificationCode,
         updateResponseNotificationsEmailAddress, addRespondentCommentOnResponse, deleteLastRespondentCommentOnResponse } from '../../graphql/mutations'
import { getRowBgColorAndIcon, graphqlQueryAllowingBothLoginStates } from '../../utils';
import { IoTrash, IoCaretForward, IoPlayBack } from "react-icons/io5";
import { FaAsterisk, FaPen, FaTimesCircle } from "react-icons/fa";

const EmailModificationState = {
  NotEditingEmail: "NotEditingEmail",
  EditingEmail: "EditingEmail",
  SubmittingEmail: "SubmittingEmail" // this includes removing email
};

const ResponseProcessingState = {
  PROCESSING: 'processing',
  PROCESSED: 'processed',
  ERROR: 'error'
};

const POLL_INITIAL_DELAY_MS = 2000;
const POLL_BACKOFF_FACTOR = 1.5;
const MAX_COMMENT_LEN = 200;

function PublicSurveyResponseDetails() {
  const { responseRespondentKeyId } = useParams();
  const [responseProcessingState, setResponseProcessingState] = useState(ResponseProcessingState.PROCESSING);
  const [surveyResponse, setSurveyResponse] = useState(null);
  const [surveyContext, setSurveyContext] = useState(null);
  const [surveyThemes, setSurveyThemes] = useState(null);
  // use this to hide the pagination buttons when we find that there was an empty last page of results
  // (due to how appsync/dynamodb pagination works); unfortunately this only works after the user tries to step to that page
  const [surveyThemesHasEmptyLastPage, setSurveyThemesHasEmptyLastPage] = useState(false);
  const [emailVerificationCode, setEmailVerificationCode] = useState('');
  const [emailVerificationCodeRequestInProgress, setEmailVerificationCodeRequestInProgress] = useState(false);
  const [emailVerificationMessage, setEmailVerificationMessage] = useState(null);
  const [emailModificationState, setEmailModificationState] = useState(EmailModificationState.NotEditingEmail);
  const [modifiedEmail, setModifiedEmail] = useState('');

  const [newComment, setNewComment] = useState("");
  const [commentError, setCommentError] = useState(null);
  const [changingComments, setChangingComments] = useState(false);

  // returns true if we should continue polling for updates (i.e., we are waiting for the state to change)
  const fetchResponse = useCallback(async (responseRespondentKeyId) => {
    try {
      const surveyResponseData = await graphqlQueryAllowingBothLoginStates(
        getPublicSurveyResponseDetails,
        { responseRespondentKeyId: responseRespondentKeyId });
      const surveyResponse = surveyResponseData.data.getPublicSurveyResponseDetails;
      if (!surveyResponse) {
        throw new Error("Survey response not found")
      }
      setSurveyResponse(surveyResponse);

      console.log("poll received response state:", surveyResponse.state);
      switch (surveyResponse.state) {
        case 'VALIDATED':
          setResponseProcessingState(ResponseProcessingState.PROCESSING);
          return true;
        case 'PROCESSED':
          setResponseProcessingState(ResponseProcessingState.PROCESSED);
          return false;
        default:
          // normally we should not have arrived at this page if the state wasn't one of the ones above
          setResponseProcessingState(ResponseProcessingState.ERROR);
          console.log('unexpected state:', surveyResponse);
          return false;
      }
    } catch (err) {
      console.log('error fetching response', err);
      return true;
    }
  }, []);

  const startPollingForResponseUpdates = useCallback(async (responseRespondentKeyId) => {
    var delay = POLL_INITIAL_DELAY_MS;
    const pollWithBackoff = async () => {
      if (await fetchResponse(responseRespondentKeyId)) {
        delay *= POLL_BACKOFF_FACTOR;
        setTimeout(pollWithBackoff, delay);
      }
    };
    pollWithBackoff();
  }, [fetchResponse]);


  useEffect(() => {
    async function fetchSurvey() {
      if (surveyResponse != null && surveyContext == null) {
        const surveyData = await graphqlQueryAllowingBothLoginStates(
          getPublicSurveyDetails,
          { surveyId: surveyResponse.surveyId });
        const survey = surveyData.data.getPublicSurveyDetails
        if (!survey) {
          throw new Error("Survey not found")
        }
        setSurveyContext(survey)
      }
    }
  
    fetchSurvey();
  }, [surveyContext, surveyResponse]);

  useEffect(() => {
    async function initResponseState() {
      if (fetchResponse(responseRespondentKeyId)) {
        startPollingForResponseUpdates(responseRespondentKeyId);
      }
    }
  
    initResponseState();
  }, [responseRespondentKeyId, fetchResponse, startPollingForResponseUpdates]);

  useEffect(() => {
    if (surveyContext != null && surveyResponse != null) {
      document.title = `My response to survey about ${surveyContext.entityName} - ${surveyResponse.processedAt != null ? new Date(surveyResponse.processedAt * 1000).toLocaleDateString() : "processing"} - ActionaBull`
    }
  }, [surveyContext, surveyResponse]);

  const updateThemesForSurvey = useCallback(async (nextToken) => {
    if (!surveyContext ||
        !surveyContext.respondentsCanSeeThemes ||
        surveyResponse.themes == null ||
        surveyResponse.themes.length === 0) {
      setSurveyThemesHasEmptyLastPage(false);
      setSurveyThemes(null);
      return;
    }
    const surveyThemesResponse = await graphqlQueryAllowingBothLoginStates(
      getPublicSurveyThemes,
      {
        surveyId: surveyResponse.surveyId,
        nextToken: nextToken
      });
    const themesData = surveyThemesResponse.data.getPublicSurveyThemes
    if (!themesData) {
      throw new Error("Themes not found")
    }
    themesData.isFirstPage = nextToken === null

    if (themesData.themes.length === 0 && !themesData.isFirstPage) {
      setSurveyThemesHasEmptyLastPage(true);
      // don't touch themes state
    } else {
      setSurveyThemesHasEmptyLastPage(false);
      setSurveyThemes(themesData);
    }
  }, [surveyContext, surveyResponse]);

  useEffect(() => {
    updateThemesForSurvey(null);
  }, [surveyContext, surveyResponse, updateThemesForSurvey]);

  const handleVerificationCodeChange = (event) => {
    const { value } = event.target;
    setEmailVerificationCode(value);
  };

  const submitEmailVerificationCode = useCallback(async (event) => {
    event.preventDefault();
    let emailVerificationCodeAsInt;
    try {
      emailVerificationCodeAsInt = parseInt(emailVerificationCode);
      if (isNaN(emailVerificationCodeAsInt)) {
        throw new Error("Invalid code");
      }
    } catch (err) {
      setEmailVerificationMessage("Error: Invalid code");
      return;
    }
    setEmailVerificationCodeRequestInProgress(true);
    try {
      const response = await graphqlQueryAllowingBothLoginStates(
        verifyResponseNotificationsEmailAddress,
        {
          responseRespondentKeyId: responseRespondentKeyId,
          verificationCode: emailVerificationCodeAsInt
         });
      const status = response.data.verifyResponseNotificationsEmailAddress;
      if (status === "VERIFIED") {
        setSurveyResponse(surveyResponse => ({ ...surveyResponse, pendingEmailVerification: false, respondentReceivingEmailUpdates: true}));
        setEmailVerificationMessage(null);
      } else if (status === "INVALID_CODE") {
        setEmailVerificationMessage("Error: Invalid code");
      } else if (status === "TOO_MANY_ATTEMPTS") {
        setEmailVerificationMessage("Error: Maximum number of attempts reached");
      } else {
        setEmailVerificationMessage("Sorry, an error occurred");
      }
    } finally {
      setEmailVerificationCodeRequestInProgress(false);
    }
  }, [emailVerificationCode, responseRespondentKeyId]);

  const resendVerificationCode = useCallback(async (event) => {
    event.preventDefault();
    setEmailVerificationCodeRequestInProgress(true);
    try {
      const result = await graphqlQueryAllowingBothLoginStates(
        resendResponseNotificationsEmailAddressVerificationCode,
        {
          responseRespondentKeyId: responseRespondentKeyId
         });
      if (!result) {
        setEmailVerificationMessage("Sorry, an error occurred");
      } else {
        setEmailVerificationMessage("Email resent");
      }
    } finally {
      setEmailVerificationCodeRequestInProgress(false);
    }
  }, [responseRespondentKeyId]);

  const startEditingEmail = () => {
    setModifiedEmail(surveyResponse.sendUpdatesToEmail ?? "");
    setEmailModificationState(EmailModificationState.EditingEmail);
  };

  function resetEmailVerificationState() {
    setEmailVerificationCode('');
    setEmailVerificationMessage(null);
  }

  async function saveModifiedEmail(newEmail) {
    const origEmailModState = emailModificationState;
    setEmailModificationState(EmailModificationState.SubmittingEmail);
    try {
      const trimmedEmail = newEmail?.trim();
      await graphqlQueryAllowingBothLoginStates(
        updateResponseNotificationsEmailAddress,
        {
          responseRespondentKeyId: responseRespondentKeyId,
          newEmailAddress: trimmedEmail
          });
      setSurveyResponse({ ...surveyResponse,
          sendUpdatesToEmail: trimmedEmail,
          pendingEmailVerification: trimmedEmail != null && trimmedEmail.length > 0,
          respondentReceivingEmailUpdates: false });
      setModifiedEmail('');
      setEmailModificationState(EmailModificationState.NotEditingEmail);
      resetEmailVerificationState();
    } catch (err) {
      setEmailModificationState(origEmailModState);
      throw err;
    }
  }

  const cancelEmailModifications = () => {
    setModifiedEmail('');
    setEmailModificationState(EmailModificationState.NotEditingEmail);
  };

  const handleNewCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const addComment = async (event) => {
    event.preventDefault();

    setChangingComments(true);
    setCommentError(null);

    const newCommentText = newComment.trim();
    if (newCommentText.length === 0) {
      setCommentError("Comment cannot be empty");
      return;
    }
    if (newCommentText.length > MAX_COMMENT_LEN) {
      setCommentError("Comment cannot be longer than 200 characters");
      return;
    }

    try {
      const response = await graphqlQueryAllowingBothLoginStates(
        addRespondentCommentOnResponse,
        {
          responseRespondentKeyId: responseRespondentKeyId,
          comment: newCommentText,
          commentVersion: surveyResponse.commentVersion
        });
      const updateResponse = response.data.addRespondentCommentOnResponse;
      if (updateResponse == null) {
        console.log("no response");
        setCommentError("An unexpected error occurred.");
        return;
      }
      setSurveyResponse(surveyResponse => ({ ...surveyResponse,
            commentVersion: updateResponse.commentVersion,
            comments: updateResponse.comments}));
      if (updateResponse.wasSuccessful) {
        setNewComment("");
      } else {
        setCommentError("There was an error adding the comment. Please try again.");
      }
    } catch (err) {
      console.log('error adding comment', err);
      setCommentError("There was an error adding the comment");
    } finally {
      setChangingComments(false);
    }
  }

  const deleteLastComment = async () => {
    setChangingComments(true);
    setCommentError(null);
    try {
      const response = await graphqlQueryAllowingBothLoginStates(
        deleteLastRespondentCommentOnResponse,
        {
          responseRespondentKeyId: responseRespondentKeyId,
          commentVersion: surveyResponse.commentVersion
        });
      const updateResponse = response.data.deleteLastRespondentCommentOnResponse;
      if (updateResponse == null) {
        console.log("no response");
        setCommentError("An unexpected error occurred.");
        return;
      }
      setSurveyResponse(surveyResponse => ({ ...surveyResponse,
            comments: updateResponse.comments,
            commentVersion: updateResponse.commentVersion}));
      if (!updateResponse.wasSuccessful) {
        setCommentError("There was an error deleting the comment. Please try again.");
      }
    } catch (err) {
      console.log('error deleting comment', err)
      setCommentError("There was an error deleting the comment.");
    } finally {
      setChangingComments(false);
    }
  }

  const responseEmailFieldNote = "Configuring notifications encourages survey publisher to comment on your response. Email is not shared with publisher.";

  if (surveyResponse == null || surveyContext == null) {
    return <div className="m-5">Loading...</div>;
  }

  return (
    <>
      <Container className='py-5'>
        <Row className="my-3 align-items-center">
          <Col className="col-12">
            <em>Feedback about: <b>{surveyContext.entityName}</b></em>
          </Col>
        </Row>
        <Row className="mt-3 align-items-center">
          <Col className="col-12">
            <h1>{surveyContext.surveyTitle}</h1>
          </Col>
        </Row>
        <Row className="my-3 align-items-center">
          <Col className="col-12 mb-2">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Prompt</Accordion.Header>
                <Accordion.Body>
                  {surveyContext.questionText.split('\n').map((item, key) => {
                      return <span key={key}>{item}<br /></span>
                    })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
        <Row className="align-items-start mt-4">
          <Col className="col-12"><b>Here is your response</b> from {surveyResponse.processedAt ? new Date(surveyResponse.processedAt * 1000).toLocaleDateString() : "(processing date not yet available)"}:</Col>
        </Row>
        <Row className="mt-2 mb-3 align-items-start">
          <Col className="col-12 mx-4 bg-light p-3">
            {surveyResponse.text.split('\n').map((item, key, array) => {
              return (
                <span key={key}>
                  {item}
                  {key !== array.length - 1 && <br />}
                </span>
              )
            })}
          </Col>
        </Row>

        <Row className="align-items-start mt-4">
          <Col className="col-12"><b>The following themes</b> matched with your response:</Col>
        </Row>
        <Row className="mt-2 mb-3 align-items-start">
          <Col className="col-12 mx-3">
            <ListGroup>
              {responseProcessingState === ResponseProcessingState.PROCESSED && surveyResponse.themes && surveyResponse.themes.length > 0 ?
                surveyResponse.themes.map((theme, index) => (
                  <ListGroup.Item key={index} style={{ backgroundColor: getRowBgColorAndIcon(theme.sentiment)[0] }}>
                    <span>{theme.text}&nbsp;&nbsp;
                    {getRowBgColorAndIcon(theme.sentiment)[1]}</span>
                    {theme.comments && theme.comments.length > 0 && (
                      <Stack>
                        {theme.comments.map((comment, jndex) => {
                          const commentLines = comment.text.split('\n');
                          return (
                            <div key={jndex} className="small p-2 m-1 " style={{ backgroundColor: "white" }}>
                              {commentLines.map((line, kndex) => (
                                <span key={kndex}>
                                  {line}&nbsp;
                                  {kndex === commentLines.length - 1 && surveyResponse.processedAt != null && (new Date(comment.createdAt) > new Date(surveyResponse.processedAt * 1000)) && <FaAsterisk className="text-success mb-1" title="Comment was added after your response" />}
                                  <br />
                                </span>
                              ))}
                              <span className="text-muted">{new Date(comment.createdAt).toLocaleString()} by survey publisher</span>
                            </div>
                          )
                        })}
                      </Stack>
                    )}
                  </ListGroup.Item>
                ))
              : (
                <ListGroup.Item>
                  {responseProcessingState === ResponseProcessingState.PROCESSING ? (
                    <>matching in progress <Spinner animation="border" size="sm" /></>
                  ) : responseProcessingState === ResponseProcessingState.PROCESSED ? (
                    <>(no themes matched)</>
                  ) : (
                    <Badge bg="danger">An error occurred</Badge>
                  )}
                </ListGroup.Item>
              )}
            </ListGroup>
          </Col>
        </Row>

        {emailModificationState === EmailModificationState.NotEditingEmail ? (
          <>
            <Row className="align-items-start mt-4">
              <Col className="col-12">
                You will be notified about changes at:&nbsp;
                {surveyResponse.sendUpdatesToEmail == null ? (
                  <>
                    <span className="text-danger">not configured</span>
                    <Button className="py-0 ps-2 pe-0" variant="link" size="sm" onClick={() => {startEditingEmail()}}>
                      <FaPen className="mb-1" />
                    </Button>
                  </>
                ) : (
                  <>
                    <span>{surveyResponse.sendUpdatesToEmail}</span>
                    {surveyResponse.pendingEmailVerification && (
                      <span className="text-warning">&nbsp;(pending verification)</span>
                    )}
                    <Button className="py-0 ps-2 pe-0" variant="link" size="sm" onClick={() => {startEditingEmail()}}>
                      <FaPen className="mb-1" />
                    </Button>
                    <Button className="py-0 ps-2 pe-0" variant="link" size="sm" onClick={() => {saveModifiedEmail(null)}}>
                      <FaTimesCircle className="mb-1" />
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            {surveyResponse.sendUpdatesToEmail == null && (
              <Row className="align-items-start mt-0 mb-3">
                <Col className="col-12 mx-4 p-0">
                  <Form.Text muted className="">{responseEmailFieldNote}</Form.Text>
                </Col>
              </Row>
            )}
            {surveyResponse.pendingEmailVerification && (
              <Alert variant="warning">
                <Form.Group>
                  <Form.Label className='mb-1' htmlFor="name">Verify your email:</Form.Label>
                  <Stack direction="horizontal" gap={2}>
                    <Form.Control
                      type="text"
                      placeholder="000000"
                      id="emailVerificationCode"
                      name="emailVerificationCode"
                      value={emailVerificationCode}
                      onChange={handleVerificationCodeChange}
                      required
                      maxLength="8"
                      size="sm"
                      style={{width: "10em"}}
                    />
                    <Button
                      onClick={submitEmailVerificationCode}
                      variant="primary"
                      size="sm"
                      disabled={emailVerificationCodeRequestInProgress}>
                      Submit
                    </Button>
                    <Button onClick={resendVerificationCode} variant="secondary" size="sm" className="" disabled={emailVerificationCodeRequestInProgress}>
                      Resend
                    </Button>
                  </Stack>
                  {emailVerificationMessage != null && (
                    <div className="text-secondary">{emailVerificationMessage}</div>
                  )}
                  <Form.Text muted className="">Check your email for code.</Form.Text>
                </Form.Group>
              </Alert>
            )}
          </>
        ) : (
          <>
            <Row className="align-items-start mt-4">
              <Col className="col-12">
                You will be notified about changes at:&nbsp;
              </Col>
            </Row>
            <Row className="align-items-start mt-2 justify-content-left">
              <Col xs={5} className="ps-4 pe-0">
                <Form.Control
                  type="email"
                  placeholder="your email"
                  id="email"
                  name="email"
                  value={modifiedEmail}
                  onChange={(event) => {setModifiedEmail(event.target.value)}}
                  disabled={emailModificationState === EmailModificationState.SubmittingEmail}
                  required
                  size="sm"
                />
              </Col>
              <Col xs={7} className="ps-0">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {saveModifiedEmail(modifiedEmail)}}
                  className='ms-2'
                  disabled={emailModificationState === EmailModificationState.SubmittingEmail} >
                  Save
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {cancelEmailModifications()}}
                  className='ms-2'
                  disabled={emailModificationState === EmailModificationState.SubmittingEmail}>
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row className="align-items-start mb-3">
              <Col className="col-12 mx-4 p-0 px-1">
                <Form.Text muted className="">{responseEmailFieldNote}</Form.Text>
              </Col>
            </Row>
          </>
        )}

        <Row className="align-items-start mt-4">
          <Col className="col-12">Comments on your response:</Col>
        </Row>
        <Row className="mt-2 mb-3 align-items-start">
          <Col className="col-12">
            <Stack>
              <div className="small mx-3 mt-1 mb-2">
                <Form onSubmit={addComment}>
                  <Form.Group className='m-0'>
                    <Stack direction="horizontal" gap={2}>
                      <input
                        type="text"
                        className="form-control"
                        id="newComment"
                        name="newComment"
                        placeholder="Write your comment here"
                        value={newComment}
                        onChange={handleNewCommentChange}
                        required
                        maxLength={MAX_COMMENT_LEN + 10}
                        disabled={changingComments || !surveyResponse.respondentReceivingEmailUpdates}
                      />
                      <button type="submit" className="btn btn-primary ms-auto" disabled={changingComments || !surveyResponse.respondentReceivingEmailUpdates}>
                        Add
                      </button>
                    </Stack>
                    {!surveyResponse.respondentReceivingEmailUpdates && (
                      <Form.Text className='ms-1 text-muted'>
                        Email notifications must be enabled to add comments.
                      </Form.Text>
                    )}
                  </Form.Group>
                </Form>
                {commentError && <div className="alert alert-danger mt-3" role="alert">{commentError}</div>}
              </div>
              {surveyResponse.comments && surveyResponse.comments.length > 0 && surveyResponse.comments.map((comment, jndex) => (
                <div key={jndex} className="small mx-3 mt-1 mb-2">
                  <Stack direction="horizontal" gap={3}>
                    <div>
                      {comment.text.split('\n').map((line, kndex) => (
                        <span key={kndex}>{line}<br /></span>
                      ))}
                    </div>
                    {jndex === 0 && comment.commenter === "RESPONDENT" && (
                      <Button className="ms-auto" variant="link" size="sm" onClick={() => {deleteLastComment()}}>
                        <IoTrash className="text-danger mb-1" />
                      </Button>
                    )}
                  </Stack>
                  <span className="text-muted">{new Date(comment.createdAt).toLocaleString()} by {comment.commenter === "RESPONDENT" ? "you" : "survey publisher"}</span>
                </div>
              ))}
            </Stack>
          </Col>
        </Row>

        { surveyThemes != null && (
          <>
            <Row className="align-items-start mt-4">
              <Col className="col-12">Common themes on this survey:</Col>
            </Row>
            <Row className="mt-2 mb-3 align-items-start">
              <Col className="col-12 mx-3">
                <ListGroup>
                  {surveyThemes.themes.map((theme, index) => (
                    <ListGroup.Item key={index} style={{ backgroundColor: getRowBgColorAndIcon(theme.sentiment)[0] }}>
                      <span>{theme.text}&nbsp;&nbsp;{getRowBgColorAndIcon(theme.sentiment)[1]}</span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                {(!surveyThemes.isFirstPage || surveyThemes.nextToken !== null) && (
                  <div className="d-flex justify-content-end mt-3">
                    <ButtonGroup size="">
                      <Button variant="secondary" disabled={surveyThemes.isFirstPage} onClick={() => updateThemesForSurvey(null)}><IoPlayBack /></Button>
                      <Button variant="secondary" disabled={surveyThemes.nextToken === null || surveyThemesHasEmptyLastPage} onClick={() => updateThemesForSurvey(surveyThemes.nextToken)}><IoCaretForward/></Button>
                    </ButtonGroup>
                  </div>
                )}
              </Col>
            </Row>
          </>
        )}

        <Row className="my-5 align-items-start">
          <Col className="col-12">
            <Link to="/a/about"><b>Click here</b></Link> to start creating your own ActionaBull 🐂 survey.
          </Col>
        </Row>

      </Container>
      <footer style={{ backgroundColor: 'black', fontSize: 'small', color: '#aaa', textAlign: 'center', padding: '.6rem', position: 'fixed', bottom: '0', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <div><a href="https://actionabull.com">ActionaBull</a> &copy; ReadyRickshaw, LLC</div>
        <div><Link to={'/privacy'}>privacy</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/terms'}>terms</Link></div>
      </footer>
    </>
  );
}

export default PublicSurveyResponseDetails;
