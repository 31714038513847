import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Alert, Badge, Table } from 'react-bootstrap';
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { AccountSettings } from '@aws-amplify/ui-react';
import { getResponseCreditCheckoutSession, getPricing } from '../../graphql/queries';
import { useNavigate } from 'react-router-dom';
import { getOrCreateCurrentUserSettingsImpl } from '../../utils';

function Account() {
  const [showPurchaseConfirmation, setShowPurchaseConfirmation] = useState(false);
  const [userSettings, setUserSettings] = useState(null);
  const [email, setEmail] = useState(null);
  const [pricing, setPricing] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const purchaseConfirmed = searchParams.get('purchaseConfirmed');
    if (purchaseConfirmed === 'true') {
      setShowPurchaseConfirmation(true);
      navigate(window.location.pathname);
    }
  }, [navigate]);

  useEffect(() => {
    async function fetchSettings() {
      setUserSettings(await getOrCreateCurrentUserSettingsImpl(true));

      const user = await Auth.currentAuthenticatedUser();
      setEmail(user.attributes.email);

      const pricingResponse = await API.graphql(graphqlOperation(getPricing));
      console.log('pricingResponse:', pricingResponse);
      setPricing(pricingResponse.data.getPricing);
    }
    fetchSettings();
  }, [setUserSettings, setPricing]);

  const passwordChangeSuccessful = () => {
    alert('password is successfully changed!')
  }

  const purchaseCredits = async (priceId, quantity) => {
    try {
      const sessionResponse = await API.graphql(graphqlOperation(
        getResponseCreditCheckoutSession,
        {priceId: priceId, quantity: quantity},
        // pass the ID token instead of the access token so that the backend gets the user email
        (await Auth.currentSession()).idToken.jwtToken))
      console.log("Session response: " + JSON.stringify(sessionResponse));
      const checkoutUrl = sessionResponse.data.getResponseCreditCheckoutSession.checkoutUrl;
      console.log("Checkout URL: " + checkoutUrl);
      window.location.href = checkoutUrl;
    } catch (err) {
      console.log("Error getting checkout session: " + err);
      return;
    }
  }
  
  if (!userSettings) {
    return <div className="m-5">Loading...</div>;
  }

  return (

    <Container className="my-5">
      <Row>
        <Col className="col-12 col-md-8 offset-md-2">
          <h1>My Account</h1>
        </Col>
      </Row>
      <Row>
        <Col className="fw-bold text-end col-12 col-md-2 offset-md-2 my-2">
          Email
        </Col>
        <Col className="col-12 col-md-5 my-2">
          {email ? (
            email
           ) : (
            <>Loading...</>
           )}
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-md-8 offset-md-2 mt-4">
          <h2>Response Credits</h2>
        </Col>
      </Row>
      {showPurchaseConfirmation && (
        <Row>
          <Col className="col-12 col-md-8 offset-md-2">
            <Alert variant="success">
                Purchase successful.
            </Alert>
          </Col>
        </Row>
       )}
      <Row>
        <Col className="fw-bold text-end col-12 col-md-2 offset-md-2 my-2">
          Current balance
        </Col>
        <Col className="col-12 col-md-5 my-2">
          {userSettings.responseCreditsAvailable} credit{userSettings.responseCreditsAvailable === 1 ? "" : "s"}
          {userSettings.responseCreditsAvailable < 3 ? (
            <Badge className="ms-2" bg="danger">Low balance</Badge>
          ) : userSettings.lowResponseCreditsWarning && (
            <Badge className="ms-2" bg="warning">Low balance</Badge>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="fw-bold text-end col-12 col-md-2 offset-md-2 my-2">
          Add credits
        </Col>
        <Col className="col-12 col-md-5 my-2">
          {pricing ? (
            pricing.map((priceOption, index) => (
              <div>
                <Button size="sm" className="m-1" onClick={() => {purchaseCredits(priceOption.id, priceOption.quantity)}}>
                  Buy {priceOption.quantity}
                </Button>
                &nbsp;&nbsp;{priceOption.price.toLocaleString('en-US', { style: 'currency', currency: priceOption.currency })}
              </div>
            ))
           ) : (
            <>Loading...</>
           )}
        </Col>
      </Row>
      {userSettings.purchaseHistory.length > 0 && (
        <Row>
          <Col className="fw-bold text-end col-12 col-md-2 offset-md-2 my-2">
            Purchase History
          </Col>
          <Col className="col-12 col-md-5 my-2">
            <Table size="sm" className="border">
              <thead>
                <tr>
                  <th className="p-1 text-start align-middle"></th>
                  <th className="p-1 text-start align-middle"></th>
                  <th className="p-1 text-end align-middle">Quantity</th>
                  <th className="p-1 text-end align-middle">Paid</th>
                </tr>
              </thead>
              <tbody>
                {userSettings.purchaseHistory.map((purchase, index) => (
                  <tr key={index}>
                    <td className="p-1 text-start align-middle">{new Date(purchase.purchaseDate).toLocaleDateString()}</td>
                    <td className="p-1 text-start align-middle">{new Date(purchase.purchaseDate).toLocaleTimeString()}</td>
                    <td className="p-1 text-end align-middle">{purchase.numCredits}</td>
                    <td className="p-1 text-end align-middle">{purchase.purchaseAmount.toLocaleString('en-US', { style: 'currency', currency: purchase.currency })}</td>
                  </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
       )}
      <Row>
        <Col className="col-12 col-md-8 offset-md-2 mt-4">
          <h2>Change Password</h2>
          <AccountSettings.ChangePassword onSuccess={passwordChangeSuccessful}/>
            </Col>
          </Row>
    </Container>
  );
}

export default Account;
