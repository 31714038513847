import React, { useEffect, useState } from 'react';

import { Button, useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { getOrCreateCurrentUserSettingsImpl } from '../utils';

function SignInHeaderItem() {

  const { route } = useAuthenticator(context => [context.route]);
  const { signOut } = useAuthenticator((context) => [context.user]);
  const [userSettings, setUserSettings] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUserSettings() {
      // console.log('fetchUserSettings', route, userSettings);
      if (route === 'authenticated' && userSettings == null) {
        try {
          setUserSettings(await getOrCreateCurrentUserSettingsImpl(false));
        } catch (err) {
          console.log('error fetch userSettings:', err);
        }
      }
    }
    fetchUserSettings();
  }, [route, userSettings, setUserSettings]);

  const signIn = () => {
    navigate('/a/surveys');
  }

  const getResponseCreditsAlertClass = () => {
    if (userSettings == null) {
      return '';
    } else if (userSettings.responseCreditsAvailable < 3) {
      return 'text-danger';
    } else if (userSettings.lowResponseCreditsWarning) {
      return 'text-warning';
    } else {
      return '';
    }
  }

  return (
    <>
      {route === null ? (
        <span>...</span>
      ) : (route === 'authenticated' && userSettings != null) ? (
        <>
          <span className="mx-1">Welcome.</span>
          <span className={"d-none d-lg-inline " + getResponseCreditsAlertClass()}>You have {userSettings.responseCreditsAvailable} credit{userSettings.responseCreditsAvailable === 1 ? "" : "s"}.</span>
          <a href="/a/account" className="mx-1">My Account</a>
          <Button size="small" type="button" className="mx-2" onClick={() => {signOut()}}>Sign Out</Button>
        </>
      ) : (
        <Button size="small" type="button" className="mx-2" onClick={() => {signIn()}}>Sign In</Button>
      )}
    </>
  );
}

export default SignInHeaderItem;
