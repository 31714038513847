/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getSurvey(id: $id) {
      id
      surveyOwner
      isUnowned
      surveyName
      entityName
      surveyTitle
      questionText
      createdAt
      numResponses
      freeResponseCreditsRemaining
      responseCreditsConsumed
      largestLengthCategoryAllowed
      sendEmailUpdates
      respondentsCanSeeThemes
      closed
      responses {
        items {
          id
          surveyID
          surveyOwner
          submitterUsername
          state
          duplicatedResponseID
          rawResponse
          processedAt
          length
          lengthCategory
          commentVersion
          sendUpdatesToEmail
          pendingEmailVerificationCode
          emailVerificationAttempts
          respondentReceivingEmailUpdates
          createdAt
          updatedAt
          surveyResponseRespondentKeyId
          __typename
        }
        nextToken
        __typename
      }
      nextAllowedResponsesExportDate
      themes {
        items {
          id
          surveyOwner
          surveyID
          surveyIDHidden
          text
          sentiment
          numOccurrences
          numOccurrencesSinceLastComment
          commentVersion
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hiddenThemes {
        items {
          id
          surveyOwner
          surveyID
          surveyIDHidden
          text
          sentiment
          numOccurrences
          numOccurrencesSinceLastComment
          commentVersion
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hasResponsesSinceLastStats
      statsEntriesDaily {
        items {
          surveyOwner
          surveyIDDaily
          surveyIDWeekly
          date
          label
          numResponses
          numThemeMatchesPos
          numThemeMatchesNeu
          numThemeMatchesNeg
          textSummary
          graphImageUrl
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      statsEntriesWeekly {
        items {
          surveyOwner
          surveyIDDaily
          surveyIDWeekly
          date
          label
          numResponses
          numThemeMatchesPos
          numThemeMatchesNeu
          numThemeMatchesNeg
          textSummary
          graphImageUrl
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      shareUrl
      updatedAt
      __typename
    }
  }
`;
export const listSurveys = /* GraphQL */ `
  query ListSurveys(
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        surveyOwner
        isUnowned
        surveyName
        entityName
        surveyTitle
        questionText
        createdAt
        numResponses
        freeResponseCreditsRemaining
        responseCreditsConsumed
        largestLengthCategoryAllowed
        sendEmailUpdates
        respondentsCanSeeThemes
        closed
        responses {
          nextToken
          __typename
        }
        nextAllowedResponsesExportDate
        themes {
          nextToken
          __typename
        }
        hiddenThemes {
          nextToken
          __typename
        }
        hasResponsesSinceLastStats
        statsEntriesDaily {
          nextToken
          __typename
        }
        statsEntriesWeekly {
          nextToken
          __typename
        }
        shareUrl
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const surveysBySurveyOwnerAndCreatedAt = /* GraphQL */ `
  query SurveysBySurveyOwnerAndCreatedAt(
    $surveyOwner: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveysBySurveyOwnerAndCreatedAt(
      surveyOwner: $surveyOwner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surveyOwner
        isUnowned
        surveyName
        entityName
        surveyTitle
        questionText
        createdAt
        numResponses
        freeResponseCreditsRemaining
        responseCreditsConsumed
        largestLengthCategoryAllowed
        sendEmailUpdates
        respondentsCanSeeThemes
        closed
        responses {
          nextToken
          __typename
        }
        nextAllowedResponsesExportDate
        themes {
          nextToken
          __typename
        }
        hiddenThemes {
          nextToken
          __typename
        }
        hasResponsesSinceLastStats
        statsEntriesDaily {
          nextToken
          __typename
        }
        statsEntriesWeekly {
          nextToken
          __typename
        }
        shareUrl
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const surveysByHasResponsesSinceLastStats = /* GraphQL */ `
  query SurveysByHasResponsesSinceLastStats(
    $hasResponsesSinceLastStats: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveysByHasResponsesSinceLastStats(
      hasResponsesSinceLastStats: $hasResponsesSinceLastStats
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surveyOwner
        isUnowned
        surveyName
        entityName
        surveyTitle
        questionText
        createdAt
        numResponses
        freeResponseCreditsRemaining
        responseCreditsConsumed
        largestLengthCategoryAllowed
        sendEmailUpdates
        respondentsCanSeeThemes
        closed
        responses {
          nextToken
          __typename
        }
        nextAllowedResponsesExportDate
        themes {
          nextToken
          __typename
        }
        hiddenThemes {
          nextToken
          __typename
        }
        hasResponsesSinceLastStats
        statsEntriesDaily {
          nextToken
          __typename
        }
        statsEntriesWeekly {
          nextToken
          __typename
        }
        shareUrl
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSurveyStatsEntry = /* GraphQL */ `
  query GetSurveyStatsEntry($id: ID!) {
    getSurveyStatsEntry(id: $id) {
      surveyOwner
      surveyIDDaily
      surveyIDWeekly
      date
      label
      numResponses
      numThemeMatchesPos
      numThemeMatchesNeu
      numThemeMatchesNeg
      topThemes {
        themeText
        themeSentiment
        numOccurrences
        __typename
      }
      textSummary
      graphImageUrl
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSurveyStatsEntries = /* GraphQL */ `
  query ListSurveyStatsEntries(
    $filter: ModelSurveyStatsEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyStatsEntries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        surveyOwner
        surveyIDDaily
        surveyIDWeekly
        date
        label
        numResponses
        numThemeMatchesPos
        numThemeMatchesNeu
        numThemeMatchesNeg
        topThemes {
          themeText
          themeSentiment
          numOccurrences
          __typename
        }
        textSummary
        graphImageUrl
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const surveyStatsEntriesBySurveyIDDailyAndDate = /* GraphQL */ `
  query SurveyStatsEntriesBySurveyIDDailyAndDate(
    $surveyIDDaily: ID!
    $date: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyStatsEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveyStatsEntriesBySurveyIDDailyAndDate(
      surveyIDDaily: $surveyIDDaily
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        surveyOwner
        surveyIDDaily
        surveyIDWeekly
        date
        label
        numResponses
        numThemeMatchesPos
        numThemeMatchesNeu
        numThemeMatchesNeg
        topThemes {
          themeText
          themeSentiment
          numOccurrences
          __typename
        }
        textSummary
        graphImageUrl
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const surveyStatsEntriesBySurveyIDWeeklyAndDate = /* GraphQL */ `
  query SurveyStatsEntriesBySurveyIDWeeklyAndDate(
    $surveyIDWeekly: ID!
    $date: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyStatsEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveyStatsEntriesBySurveyIDWeeklyAndDate(
      surveyIDWeekly: $surveyIDWeekly
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        surveyOwner
        surveyIDDaily
        surveyIDWeekly
        date
        label
        numResponses
        numThemeMatchesPos
        numThemeMatchesNeu
        numThemeMatchesNeg
        topThemes {
          themeText
          themeSentiment
          numOccurrences
          __typename
        }
        textSummary
        graphImageUrl
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSimilarityScore = /* GraphQL */ `
  query GetSimilarityScore($id: ID!) {
    getSimilarityScore(id: $id) {
      pointText
      themeText
      similarityScore
      constFld
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSimilarityScores = /* GraphQL */ `
  query ListSimilarityScores(
    $filter: ModelSimilarityScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSimilarityScores(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pointText
        themeText
        similarityScore
        constFld
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSurveyTheme = /* GraphQL */ `
  query GetSurveyTheme($id: ID!) {
    getSurveyTheme(id: $id) {
      id
      surveyOwner
      surveyID
      surveyIDHidden
      survey {
        id
        surveyOwner
        isUnowned
        surveyName
        entityName
        surveyTitle
        questionText
        createdAt
        numResponses
        freeResponseCreditsRemaining
        responseCreditsConsumed
        largestLengthCategoryAllowed
        sendEmailUpdates
        respondentsCanSeeThemes
        closed
        responses {
          nextToken
          __typename
        }
        nextAllowedResponsesExportDate
        themes {
          nextToken
          __typename
        }
        hiddenThemes {
          nextToken
          __typename
        }
        hasResponsesSinceLastStats
        statsEntriesDaily {
          nextToken
          __typename
        }
        statsEntriesWeekly {
          nextToken
          __typename
        }
        shareUrl
        updatedAt
        __typename
      }
      text
      sentiment
      numOccurrences
      numOccurrencesSinceLastComment
      embedding {
        vector
        id
        createdAt
        updatedAt
        __typename
      }
      commentVersion
      comments {
        text
        createdAt
        commenter
        __typename
      }
      points {
        items {
          id
          surveyOwner
          createdAt
          surveyResponseID
          themeID
          text
          responseExcerpt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSurveyThemes = /* GraphQL */ `
  query ListSurveyThemes(
    $filter: ModelSurveyThemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyThemes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        surveyOwner
        surveyID
        surveyIDHidden
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        text
        sentiment
        numOccurrences
        numOccurrencesSinceLastComment
        embedding {
          vector
          id
          createdAt
          updatedAt
          __typename
        }
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        points {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const surveyThemesBySurveyIDAndNumOccurrences = /* GraphQL */ `
  query SurveyThemesBySurveyIDAndNumOccurrences(
    $surveyID: ID!
    $numOccurrences: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyThemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveyThemesBySurveyIDAndNumOccurrences(
      surveyID: $surveyID
      numOccurrences: $numOccurrences
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surveyOwner
        surveyID
        surveyIDHidden
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        text
        sentiment
        numOccurrences
        numOccurrencesSinceLastComment
        embedding {
          vector
          id
          createdAt
          updatedAt
          __typename
        }
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        points {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const surveyThemesBySurveyIDHiddenAndNumOccurrences = /* GraphQL */ `
  query SurveyThemesBySurveyIDHiddenAndNumOccurrences(
    $surveyIDHidden: ID!
    $numOccurrences: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyThemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveyThemesBySurveyIDHiddenAndNumOccurrences(
      surveyIDHidden: $surveyIDHidden
      numOccurrences: $numOccurrences
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surveyOwner
        surveyID
        surveyIDHidden
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        text
        sentiment
        numOccurrences
        numOccurrencesSinceLastComment
        embedding {
          vector
          id
          createdAt
          updatedAt
          __typename
        }
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        points {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSurveyThemeEmbedding = /* GraphQL */ `
  query GetSurveyThemeEmbedding($id: ID!) {
    getSurveyThemeEmbedding(id: $id) {
      vector
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSurveyThemeEmbeddings = /* GraphQL */ `
  query ListSurveyThemeEmbeddings(
    $filter: ModelSurveyThemeEmbeddingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyThemeEmbeddings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        vector
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAlreadySubmittedResponse = /* GraphQL */ `
  query GetAlreadySubmittedResponse(
    $surveyID: ID!
    $submitterUsername: String!
  ) {
    getAlreadySubmittedResponse(
      surveyID: $surveyID
      submitterUsername: $submitterUsername
    ) {
      surveyID
      submitterUsername
      responseID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAlreadySubmittedResponses = /* GraphQL */ `
  query ListAlreadySubmittedResponses(
    $surveyID: ID
    $submitterUsername: ModelStringKeyConditionInput
    $filter: ModelAlreadySubmittedResponseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAlreadySubmittedResponses(
      surveyID: $surveyID
      submitterUsername: $submitterUsername
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        surveyID
        submitterUsername
        responseID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSurveyResponseRespondentKey = /* GraphQL */ `
  query GetSurveyResponseRespondentKey($id: ID!) {
    getSurveyResponseRespondentKey(id: $id) {
      response {
        id
        surveyID
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        surveyOwner
        respondentKey {
          id
          createdAt
          updatedAt
          surveyResponseRespondentKeyResponseId
          __typename
        }
        submitterUsername
        state
        duplicatedResponseID
        rawResponse
        points {
          nextToken
          __typename
        }
        processedAt
        length
        lengthCategory
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        sendUpdatesToEmail
        pendingEmailVerificationCode
        emailVerificationAttempts
        respondentReceivingEmailUpdates
        themeDenorm {
          id
          text
          sentiment
          __typename
        }
        createdAt
        updatedAt
        surveyResponseRespondentKeyId
        __typename
      }
      id
      createdAt
      updatedAt
      surveyResponseRespondentKeyResponseId
      __typename
    }
  }
`;
export const listSurveyResponseRespondentKeys = /* GraphQL */ `
  query ListSurveyResponseRespondentKeys(
    $filter: ModelSurveyResponseRespondentKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyResponseRespondentKeys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        response {
          id
          surveyID
          surveyOwner
          submitterUsername
          state
          duplicatedResponseID
          rawResponse
          processedAt
          length
          lengthCategory
          commentVersion
          sendUpdatesToEmail
          pendingEmailVerificationCode
          emailVerificationAttempts
          respondentReceivingEmailUpdates
          createdAt
          updatedAt
          surveyResponseRespondentKeyId
          __typename
        }
        id
        createdAt
        updatedAt
        surveyResponseRespondentKeyResponseId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSurveyResponse = /* GraphQL */ `
  query GetSurveyResponse($id: ID!) {
    getSurveyResponse(id: $id) {
      id
      surveyID
      survey {
        id
        surveyOwner
        isUnowned
        surveyName
        entityName
        surveyTitle
        questionText
        createdAt
        numResponses
        freeResponseCreditsRemaining
        responseCreditsConsumed
        largestLengthCategoryAllowed
        sendEmailUpdates
        respondentsCanSeeThemes
        closed
        responses {
          nextToken
          __typename
        }
        nextAllowedResponsesExportDate
        themes {
          nextToken
          __typename
        }
        hiddenThemes {
          nextToken
          __typename
        }
        hasResponsesSinceLastStats
        statsEntriesDaily {
          nextToken
          __typename
        }
        statsEntriesWeekly {
          nextToken
          __typename
        }
        shareUrl
        updatedAt
        __typename
      }
      surveyOwner
      respondentKey {
        response {
          id
          surveyID
          surveyOwner
          submitterUsername
          state
          duplicatedResponseID
          rawResponse
          processedAt
          length
          lengthCategory
          commentVersion
          sendUpdatesToEmail
          pendingEmailVerificationCode
          emailVerificationAttempts
          respondentReceivingEmailUpdates
          createdAt
          updatedAt
          surveyResponseRespondentKeyId
          __typename
        }
        id
        createdAt
        updatedAt
        surveyResponseRespondentKeyResponseId
        __typename
      }
      submitterUsername
      state
      duplicatedResponseID
      rawResponse
      points {
        items {
          id
          surveyOwner
          createdAt
          surveyResponseID
          themeID
          text
          responseExcerpt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      processedAt
      length
      lengthCategory
      commentVersion
      comments {
        text
        createdAt
        commenter
        __typename
      }
      sendUpdatesToEmail
      pendingEmailVerificationCode
      emailVerificationAttempts
      respondentReceivingEmailUpdates
      themeDenorm {
        id
        text
        sentiment
        __typename
      }
      createdAt
      updatedAt
      surveyResponseRespondentKeyId
      __typename
    }
  }
`;
export const listSurveyResponses = /* GraphQL */ `
  query ListSurveyResponses(
    $filter: ModelSurveyResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        surveyID
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        surveyOwner
        respondentKey {
          id
          createdAt
          updatedAt
          surveyResponseRespondentKeyResponseId
          __typename
        }
        submitterUsername
        state
        duplicatedResponseID
        rawResponse
        points {
          nextToken
          __typename
        }
        processedAt
        length
        lengthCategory
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        sendUpdatesToEmail
        pendingEmailVerificationCode
        emailVerificationAttempts
        respondentReceivingEmailUpdates
        themeDenorm {
          id
          text
          sentiment
          __typename
        }
        createdAt
        updatedAt
        surveyResponseRespondentKeyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const surveyResponsesBySurveyIDAndProcessedAt = /* GraphQL */ `
  query SurveyResponsesBySurveyIDAndProcessedAt(
    $surveyID: ID!
    $processedAt: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveyResponsesBySurveyIDAndProcessedAt(
      surveyID: $surveyID
      processedAt: $processedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surveyID
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        surveyOwner
        respondentKey {
          id
          createdAt
          updatedAt
          surveyResponseRespondentKeyResponseId
          __typename
        }
        submitterUsername
        state
        duplicatedResponseID
        rawResponse
        points {
          nextToken
          __typename
        }
        processedAt
        length
        lengthCategory
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        sendUpdatesToEmail
        pendingEmailVerificationCode
        emailVerificationAttempts
        respondentReceivingEmailUpdates
        themeDenorm {
          id
          text
          sentiment
          __typename
        }
        createdAt
        updatedAt
        surveyResponseRespondentKeyId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSurveyResponsePoint = /* GraphQL */ `
  query GetSurveyResponsePoint($id: ID!) {
    getSurveyResponsePoint(id: $id) {
      id
      surveyOwner
      createdAt
      surveyResponseID
      surveyResponse {
        id
        surveyID
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        surveyOwner
        respondentKey {
          id
          createdAt
          updatedAt
          surveyResponseRespondentKeyResponseId
          __typename
        }
        submitterUsername
        state
        duplicatedResponseID
        rawResponse
        points {
          nextToken
          __typename
        }
        processedAt
        length
        lengthCategory
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        sendUpdatesToEmail
        pendingEmailVerificationCode
        emailVerificationAttempts
        respondentReceivingEmailUpdates
        themeDenorm {
          id
          text
          sentiment
          __typename
        }
        createdAt
        updatedAt
        surveyResponseRespondentKeyId
        __typename
      }
      themeID
      theme {
        id
        surveyOwner
        surveyID
        surveyIDHidden
        survey {
          id
          surveyOwner
          isUnowned
          surveyName
          entityName
          surveyTitle
          questionText
          createdAt
          numResponses
          freeResponseCreditsRemaining
          responseCreditsConsumed
          largestLengthCategoryAllowed
          sendEmailUpdates
          respondentsCanSeeThemes
          closed
          nextAllowedResponsesExportDate
          hasResponsesSinceLastStats
          shareUrl
          updatedAt
          __typename
        }
        text
        sentiment
        numOccurrences
        numOccurrencesSinceLastComment
        embedding {
          vector
          id
          createdAt
          updatedAt
          __typename
        }
        commentVersion
        comments {
          text
          createdAt
          commenter
          __typename
        }
        points {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      text
      responseExcerpt
      updatedAt
      __typename
    }
  }
`;
export const listSurveyResponsePoints = /* GraphQL */ `
  query ListSurveyResponsePoints(
    $filter: ModelSurveyResponsePointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyResponsePoints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surveyOwner
        createdAt
        surveyResponseID
        surveyResponse {
          id
          surveyID
          surveyOwner
          submitterUsername
          state
          duplicatedResponseID
          rawResponse
          processedAt
          length
          lengthCategory
          commentVersion
          sendUpdatesToEmail
          pendingEmailVerificationCode
          emailVerificationAttempts
          respondentReceivingEmailUpdates
          createdAt
          updatedAt
          surveyResponseRespondentKeyId
          __typename
        }
        themeID
        theme {
          id
          surveyOwner
          surveyID
          surveyIDHidden
          text
          sentiment
          numOccurrences
          numOccurrencesSinceLastComment
          commentVersion
          createdAt
          updatedAt
          __typename
        }
        text
        responseExcerpt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const surveyResponsePointsBySurveyResponseID = /* GraphQL */ `
  query SurveyResponsePointsBySurveyResponseID(
    $surveyResponseID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyResponsePointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveyResponsePointsBySurveyResponseID(
      surveyResponseID: $surveyResponseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surveyOwner
        createdAt
        surveyResponseID
        surveyResponse {
          id
          surveyID
          surveyOwner
          submitterUsername
          state
          duplicatedResponseID
          rawResponse
          processedAt
          length
          lengthCategory
          commentVersion
          sendUpdatesToEmail
          pendingEmailVerificationCode
          emailVerificationAttempts
          respondentReceivingEmailUpdates
          createdAt
          updatedAt
          surveyResponseRespondentKeyId
          __typename
        }
        themeID
        theme {
          id
          surveyOwner
          surveyID
          surveyIDHidden
          text
          sentiment
          numOccurrences
          numOccurrencesSinceLastComment
          commentVersion
          createdAt
          updatedAt
          __typename
        }
        text
        responseExcerpt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const surveyResponsePointsByThemeIDAndCreatedAt = /* GraphQL */ `
  query SurveyResponsePointsByThemeIDAndCreatedAt(
    $themeID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyResponsePointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveyResponsePointsByThemeIDAndCreatedAt(
      themeID: $themeID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surveyOwner
        createdAt
        surveyResponseID
        surveyResponse {
          id
          surveyID
          surveyOwner
          submitterUsername
          state
          duplicatedResponseID
          rawResponse
          processedAt
          length
          lengthCategory
          commentVersion
          sendUpdatesToEmail
          pendingEmailVerificationCode
          emailVerificationAttempts
          respondentReceivingEmailUpdates
          createdAt
          updatedAt
          surveyResponseRespondentKeyId
          __typename
        }
        themeID
        theme {
          id
          surveyOwner
          surveyID
          surveyIDHidden
          text
          sentiment
          numOccurrences
          numOccurrencesSinceLastComment
          commentVersion
          createdAt
          updatedAt
          __typename
        }
        text
        responseExcerpt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPublicSurveyDetails = /* GraphQL */ `
  query GetPublicSurveyDetails($surveyId: ID!) {
    getPublicSurveyDetails(surveyId: $surveyId) {
      closed
      isUnowned
      publisherHasCredits
      isCurrentUserOwner
      entityName
      surveyTitle
      questionText
      responseSizeLimit
      alreadySubmittedResponseRespondentKeyId
      largestLengthCategoryAllowed
      shareUrl
      respondentsCanSeeThemes
      surveyName
      freeResponseCreditsRemaining
      sendEmailUpdates
      __typename
    }
  }
`;
export const getPublicSurveyResponseState = /* GraphQL */ `
  query GetPublicSurveyResponseState($responseRespondentKeyId: ID!) {
    getPublicSurveyResponseState(
      responseRespondentKeyId: $responseRespondentKeyId
    ) {
      state
      duplicatedResponseRespondentKeyID
      __typename
    }
  }
`;
export const getPublicSurveyResponseDetails = /* GraphQL */ `
  query GetPublicSurveyResponseDetails($responseRespondentKeyId: ID!) {
    getPublicSurveyResponseDetails(
      responseRespondentKeyId: $responseRespondentKeyId
    ) {
      surveyId
      state
      text
      processedAt
      themes {
        sentiment
        text
        comments {
          text
          createdAt
          commenter
          __typename
        }
        __typename
      }
      comments {
        text
        createdAt
        commenter
        __typename
      }
      commentVersion
      sendUpdatesToEmail
      pendingEmailVerification
      respondentReceivingEmailUpdates
      __typename
    }
  }
`;
export const getPublicSurveyThemes = /* GraphQL */ `
  query GetPublicSurveyThemes($surveyId: ID!, $nextToken: String) {
    getPublicSurveyThemes(surveyId: $surveyId, nextToken: $nextToken) {
      themes {
        text
        sentiment
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResponseCreditCheckoutSession = /* GraphQL */ `
  query GetResponseCreditCheckoutSession($priceId: String!, $quantity: Int!) {
    getResponseCreditCheckoutSession(priceId: $priceId, quantity: $quantity) {
      checkoutUrl
      __typename
    }
  }
`;
export const getPricing = /* GraphQL */ `
  query GetPricing {
    getPricing {
      id
      quantity
      price
      currency
      __typename
    }
  }
`;
export const getNumFreeCreditsPerSurvey = /* GraphQL */ `
  query GetNumFreeCreditsPerSurvey {
    getNumFreeCreditsPerSurvey
  }
`;
export const getUserSettings = /* GraphQL */ `
  query GetUserSettings($userId: ID!) {
    getUserSettings(userId: $userId) {
      isNewlyCreated
      responseCreditsAvailable
      lowResponseCreditsWarning
      purchaseHistory {
        purchaseDate
        numCredits
        purchaseAmount
        currency
        __typename
      }
      __typename
    }
  }
`;
export const downloadResponses = /* GraphQL */ `
  query DownloadResponses($surveyId: ID!) {
    downloadResponses(surveyId: $surveyId)
  }
`;
export const getAllStatsDateLabels = /* GraphQL */ `
  query GetAllStatsDateLabels {
    getAllStatsDateLabels {
      daily {
        date
        label
        __typename
      }
      weekly {
        date
        label
        __typename
      }
      __typename
    }
  }
`;
