import { Navbar, Nav } from 'react-bootstrap';
import { Authenticator } from '@aws-amplify/ui-react';
import SignInHeaderItem from './SignInHeaderItem';
import { Link, Outlet } from 'react-router-dom';

const MainLayout = ({ children }) => {

  return (
    <Authenticator.Provider>
      <div>
        <Navbar bg="light" className="px-3" expand="md">
          <Navbar.Brand href="/">ActionaBull<sup><small style={{ color: '#999999' }}>(beta)</small></sup> <span style={{ position: 'relative', top: '-1px' }}>🐂</span></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="flex-grow-1">
              <Nav.Link href="/a/about">About</Nav.Link>
              <Nav.Link href="/a/surveys">Surveys</Nav.Link>
            </Nav>
            <SignInHeaderItem />
          </Navbar.Collapse>
        </Navbar>
        <Outlet />
      </div>
      <footer style={{ backgroundColor: 'black', fontSize: 'small', color: '#aaa', textAlign: 'center', padding: '.6rem', position: 'fixed', bottom: '0', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <div><a href="https://actionabull.com">ActionaBull</a> &copy; ReadyRickshaw, LLC</div>
        <div><Link to={'/privacy'}>privacy</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/terms'}>terms</Link></div>
      </footer>
    </Authenticator.Provider>
  );
};

export default MainLayout;