import React from 'react';

import { Heading, Text } from '@aws-amplify/ui-react';

function ErrorNotFound() {
  return (
    <div className="container">
      <Heading>Sorry, that address was not found (404)</Heading>
      <Text>Try going back to the previous page or <a href="/">return to the main page</a>.
      </Text>
    </div>
  );
}

export default ErrorNotFound;
