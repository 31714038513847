// import logo from './logo.svg';

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

import About from './pages/About';
import Surveys from './pages/admin/Surveys';
import SurveyDetails from './pages/admin/SurveyDetails';
import SurveyThemeDetails from './pages/admin/SurveyThemeDetails';
import SurveyResponseDetails from './pages/admin/SurveyResponseDetails';
import SurveyResponses from './pages/admin/SurveyResponses';
import Account from './pages/admin/Account';
import 'bootstrap/dist/css/bootstrap.css';
import CreateSurvey from './pages/admin/CreateSurvey';
import PublicSurvey from './pages/surveys/PublicSurvey';
import PublicSurveyResponseDetails from './pages/surveys/PublicSurveyResponseDetails';
import MainLayout from './components/MainLayout';
import ErrorNotFound from './pages/ErrorNotFound';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Authenticator } from '@aws-amplify/ui-react';

import './NewAge.css';
import '@aws-amplify/ui-react/styles.css';
import './App.css';

const RecaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LcFjyEnAAAAAGs1BEE_n6yJ3z4u-Dt6EB8mRStb';

function App() {

  return (
    <HelmetProvider>
      <>
        <Helmet>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,400;1,400&amp;display=swap" rel="stylesheet" />
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css"></link>
        </Helmet>
        <Router>
          <Routes>
            <Route exact path="/" element={<RedirectToAbout />} />
            <Route path="/a" element={<MainLayout />} >
              <Route path="about" element={<About />} />
              <Route path="surveys" element={<Authenticator className="my-5"><Surveys /></Authenticator>} />
              <Route path="surveys/:surveyId" element={<Authenticator className="my-5"><SurveyDetails isUnowned={false} /></Authenticator>} />
              <Route path="surveysu/:surveyId" element={<SurveyDetails isUnowned={true} />} />
              <Route path="surveys/:surveyId/themes/:themeId" element={<Authenticator className="my-5"><SurveyThemeDetails /></Authenticator>} />
              <Route path="surveys/:surveyId/responses" element={<Authenticator className="my-5"><SurveyResponses /></Authenticator>} />
              <Route path="surveys/:surveyId/responses/:responseId" element={<Authenticator className="my-5"><SurveyResponseDetails /></Authenticator>} />
              <Route path="account" element={<Authenticator className="my-5"><Account /></Authenticator>} />
              <Route path="surveys/new" element={<GoogleReCaptchaProvider reCaptchaKey={RecaptchaSiteKey}><CreateSurvey /></GoogleReCaptchaProvider>} />
              <Route path="*" element={<ErrorNotFound />} />
              <Route path="" element={<ErrorNotFound />} />
            </Route>
            <Route path="/s/:surveyId" element={<GoogleReCaptchaProvider reCaptchaKey={RecaptchaSiteKey}><PublicSurvey /></GoogleReCaptchaProvider>} />
            <Route path="/s/r/:responseRespondentKeyId" element={<PublicSurveyResponseDetails />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="*" element={<ErrorNotFound />} />
          </Routes>
        </Router>
      </>
    </HelmetProvider>
  );
}

function RedirectToAbout() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/a/about', { replace: true });
  }, [navigate]);

  return null;
}

export default App;
