import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, ButtonGroup, Breadcrumb, Container, Row, Col, Modal,
         FormControl, Accordion, Alert, Form, Stack, Tab, Tabs } from 'react-bootstrap';
import { API, graphqlOperation } from 'aws-amplify'
import { Link, useNavigate } from 'react-router-dom';
import { IoCheckmarkCircle, IoPlayBack, IoCaretForward, IoCaretBack, IoFlame, IoSettings, IoShareSocial, IoAnalytics } from "react-icons/io5";
import { getRowBgColorAndIcon, LengthCategories, unownedSurveyIdLocalStorageKey,
         graphqlQueryAllowingBothLoginStates, isCurrentUserAuthenticated } from '../../utils';
import LowResponseCreditsAlert from '../../components/LowResponseCreditsAlert';
import { getPublicSurveyDetails } from '../../graphql/queries'
import { takeOwnershipOfSurvey, generateQRCode, generateFlyer } from '../../graphql/mutations'
import { Bar } from 'react-chartjs-2';
import { LinearScale, CategoryScale, Chart, BarElement, Tooltip } from "chart.js";

Chart.register(LinearScale, CategoryScale, BarElement, Tooltip);

function SurveyDetails({ isUnowned }) {
  const { surveyId } = useParams();
  const [survey, setSurvey] = useState(null);
  const [themes, setThemes] = useState(null);
  // start in 'days' mode because there are never weekly stats without daily
  const [historyViewMode, setHistoryViewMode] = useState('weeks');

  // the value of selectedStatsWeek should be kept in sync with border color of the selected bar in the chart
  const [selectedStatsWeek, setSelectedStatsWeek] = useState(null);
  const [preparedHistoryStats, setPreparedHistoryStats] = useState(null);

  function incSelectedStatsWeek(by) {
    setSelectedStatsWeek((selectedStatsWeek + by + preparedHistoryStats.weeksChartJsData.labels.length) % preparedHistoryStats.weeksChartJsData.labels.length);
  }

  const [hiddenThemes, setHiddenThemes] = useState(null);
  const [updatingThemeVisibilityCheck, setUpdatingThemeVisibilityCheck] = useState(false);
  const [updatingSurveyDigestEmailCheck, setUpdatingSurveyDigestEmailCheck] = useState(false);

  // use this to hide the pagination buttons when we find that there was an empty last page of results
  // (due to how appsync/dynamodb pagination works); unfortunately this only works after the user tries to step to that page
  const [themesHasEmptyLastPage, setThemesHasEmptyLastPage] = useState(false);
  const [hiddenThemesHasEmptyLastPage, setHiddenThemesHasEmptyLastPage] = useState(false);

  const [hideInProgress, setHideInProgress] = useState(false);
  const [urlCopied, setUrlCopied] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [showConfigureFlyer, setShowConfigureFlyer] = useState(false);
  const [flyerIncludeQuestionText, setFlyerIncludeQuestionText] = useState(false);

  const [closeSurveyOpInProgress, setCloseSurveyOpInProgress] = useState(false);
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const navigate = useNavigate();

  const verifyOwned = async () => {
    if (isUnowned) {
      throw new Error("Page in unowned mode");
    }
  }

  useEffect(() => {
    async function checkAuthentication() {
      if (isUnowned && await isCurrentUserAuthenticated()) {
        // unowned mode is only for unauthenticated users
        navigate(`/a/surveys/${surveyId}`);
      } else if (!isUnowned && !await isCurrentUserAuthenticated()) {
        navigate(`/a/surveysu/${surveyId}`);
      }
    }

    checkAuthentication();
  }, [surveyId, navigate, isUnowned]);

  const handleDelete = async (surveyId) => {
    console.log("deleting survey")
    verifyOwned();
    try {
      const deleteSurveyQuery = /* GraphQL */ `
        mutation DeleteSurvey(
          $input: DeleteSurveyInput!
          $condition: ModelSurveyConditionInput
        ) {
          deleteSurvey(input: $input, condition: $condition) {
            id
          }
        }
      `;
      await API.graphql(graphqlOperation(deleteSurveyQuery, {input: { id: surveyId }}));
      navigate(`/a/surveys`);
    } catch (err) {
      console.log('error deleting survey:', err);
    }
  }

  function handleCopyClick() {
    if (!survey) {
      console.log("warning: survey not found, skipping copy");
      return;
    }
    navigator.clipboard.writeText(survey.shareUrl).then(() => {
      setUrlCopied(true);
    });
  }

  function handlePreviewClick() {
    if (!survey) {
      console.log("warning: survey not found, skipping preview");
      return;
    }
    window.open(survey.shareUrl, '_blank');
  }

  async function doFileDownload(query, params, queryName, filename) {
    if (!survey) {
      console.log("warning: survey not found, skipping download");
      return;
    }
    if (downloadInProgress) {
      return;
    }
    setDownloadInProgress(true);
    try {
      const urlResult = await API.graphql(graphqlOperation(query, params));
      const response = await fetch(urlResult.data[queryName]);
      const data = await response.blob();
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(data);
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.log('error downloading file', err);
      return;
    } finally {
      setDownloadInProgress(false);
    }
  }

  async function handleDownloadQRCode() {
    await doFileDownload(generateQRCode, {surveyId: surveyId}, "generateQRCode", 'survey-qrcode.png');
  }

  async function handleFlyer() {
    setShowConfigureFlyer(false);
    await doFileDownload(generateFlyer, {surveyId: surveyId, includeQuestionText: flyerIncludeQuestionText}, "generateFlyer", 'survey-flyer.pdf');
  }

  const updateThemesForSurvey = useCallback(async (nextToken) => {
    if (!survey || isUnowned) {
      // unowned surveys never have themes
      setThemesHasEmptyLastPage(false);
      setThemes({
        items: [],
        orderedItems: [],
        nextToken: null,
        isFirstPage: true
      });
      return;
    }
    const surveyThemesBySurveyIDAndNumOccurrencesQuery = /* GraphQL */ `
      query SurveyThemesBySurveyIDAndNumOccurrences(
        $surveyID: ID!
        $nextToken: String
      ) {
        surveyThemesBySurveyIDAndNumOccurrences(
          surveyID: $surveyID
          sortDirection: DESC
          limit: 15
          nextToken: $nextToken
        ) {
          items {
            id
            text
            sentiment
            numOccurrences
            numOccurrencesSinceLastComment
            comments {
              text
              createdAt
            }
          }
          nextToken
        }
      }
    `;
    const themesResponse = await API.graphql(graphqlOperation(surveyThemesBySurveyIDAndNumOccurrencesQuery, {
      surveyID: survey.id,
      nextToken: nextToken
    }));
    const themesData = themesResponse.data.surveyThemesBySurveyIDAndNumOccurrences;
    themesData.isFirstPage = nextToken === null

    if (themesData.items.length === 0 && !themesData.isFirstPage) {
      setThemesHasEmptyLastPage(true);
      // don't touch themes state
    } else {
      setThemesHasEmptyLastPage(false);
      themesData.orderedItems = (themesData.items) ?
          themesData.items.sort((a, b) => {
            if (b.numOccurrences !== a.numOccurrences) {
              return b.numOccurrences - a.numOccurrences;
            }
            const effOslc_a = a.comments && a.comments.length > 0 ? a.numOccurrencesSinceLastComment : Number.MAX_SAFE_INTEGER;
            const effOslc_b = b.comments && b.comments.length > 0 ? b.numOccurrencesSinceLastComment : Number.MAX_SAFE_INTEGER;
            return effOslc_b - effOslc_a;
          }) :
          [];

      setThemes(themesData);
    }
  }, [survey, isUnowned]);

  const updateHiddenThemesForSurvey = useCallback(async (nextToken) => {
    if (!survey || isUnowned) {
      // unowned surveys never have themes (hidden or otherwise)
      setHiddenThemesHasEmptyLastPage(false);
      setHiddenThemes({
        items: [],
        nextToken: null,
        isFirstPage: true
      });
      return;
    }

    const surveyThemesBySurveyIDHiddenAndNumOccurrencesQuery = /* GraphQL */ `
      query SurveyThemesBySurveyIDHiddenAndNumOccurrences(
        $surveyID: ID!
        $nextToken: String
      ) {
        surveyThemesBySurveyIDHiddenAndNumOccurrences(
          surveyIDHidden: $surveyID
          sortDirection: DESC
          limit: 10
          nextToken: $nextToken
        ) {
          items {
            id
            text
            sentiment
            numOccurrences
            numOccurrencesSinceLastComment
            comments {
              text
              createdAt
            }
          }
          nextToken
        }
      }
    `;
    const themesResponse = await API.graphql(graphqlOperation(surveyThemesBySurveyIDHiddenAndNumOccurrencesQuery, {
      surveyID: survey.id,
      nextToken: nextToken
    }));
    const themesData = themesResponse.data.surveyThemesBySurveyIDHiddenAndNumOccurrences;
    themesData.isFirstPage = nextToken === null

    if (themesData.items.length === 0 && !themesData.isFirstPage) {
      setHiddenThemesHasEmptyLastPage(true);
      // don't touch hiddenThemes state
    } else {
      setHiddenThemesHasEmptyLastPage(false);
      setHiddenThemes(themesData);
    }
  }, [survey, isUnowned]);

  useEffect(() => {
    async function fetchSurvey() {
      try {
        if (isUnowned) {
          const surveyResult = await graphqlQueryAllowingBothLoginStates(
            getPublicSurveyDetails,
            { surveyId: surveyId });
          const survey = surveyResult.data.getPublicSurveyDetails;
          if (!survey) {
            console.log("survey not found, redirecting to surveys");
            navigate(`/a/about`);
            return;
          }
          if (!survey.isUnowned) {
            navigate(`/a/surveys/${surveyId}`);
            return;
          }

          // set fields that are not included in public survey details, but we can assume the value for unowned
          survey.numResponses = 0;
          survey.responseCreditsConsumed = 0;

          setSurvey(survey);

          document.title = `${survey.surveyName} - ActionaBull`
        } else {
          if (localStorage.getItem(unownedSurveyIdLocalStorageKey) === surveyId) {
            // user is signed in and now trying to view the survey they created before being authenticated
            const resultObj = await API.graphql(graphqlOperation(takeOwnershipOfSurvey, {surveyId: surveyId}));
            const result =  resultObj.data.takeOwnershipOfSurvey;
            if (result != null) {
              if (result.success) {
                console.log("took ownership of survey: " + result.note);
                localStorage.removeItem(unownedSurveyIdLocalStorageKey);
              } else {
                console.log("failed to take ownership of survey: " + result.note);
              }
            } else {
              throw new Error("failed to call take ownership of survey");
            }
          }
          const getSurveyQuery = /* GraphQL */ `
            query GetSurvey($id: ID!) {
              getSurvey(id: $id) {
                id
                surveyName
                entityName
                surveyTitle
                questionText
                createdAt
                numResponses
                responseCreditsConsumed
                freeResponseCreditsRemaining
                largestLengthCategoryAllowed
                closed
                sendEmailUpdates
                respondentsCanSeeThemes
                shareUrl
                statsEntriesDaily {
                  items {
                    date
                    label
                    numResponses
                    numThemeMatchesPos
                    numThemeMatchesNeu
                    numThemeMatchesNeg
                    topThemes {
                      themeText
                      themeSentiment
                      numOccurrences
                    }
                  }
                  nextToken
                }
                statsEntriesWeekly {
                  items {
                    date
                    label
                    numResponses
                    numThemeMatchesPos
                    numThemeMatchesNeu
                    numThemeMatchesNeg
                    textSummary
                    topThemes {
                      themeText
                      themeSentiment
                      numOccurrences
                    }
                  }
                  nextToken
                }
              }
            }
          `;
          const surveyData = await API.graphql(graphqlOperation(getSurveyQuery, {id: surveyId}))
          const survey = surveyData.data.getSurvey;
          if (!survey) {
            console.log("survey not found, redirecting to surveys");
            navigate(`/a/surveys`);
            return;
          }
          if (survey.statsEntriesDaily?.nextToken != null || survey.statsEntriesWeekly?.nextToken != null) {
            console.log("warning: stats are paginated, not all stats will be shown");
          }
          setSurvey(survey);
        }
      } catch (err) {
        console.log('error fetching survey, redirecting to about', err);
        navigate(`/a/about`);
      }
    }
  
    fetchSurvey();
  }, [surveyId, navigate, isUnowned]);

  useEffect(() => {
    document.title = survey ? `${survey.surveyName} - ActionaBull` : "ActionaBull";
  }, [survey]);

  useEffect(() => {
    updateThemesForSurvey(null);
    updateHiddenThemesForSurvey(null);
  }, [survey, isUnowned, updateThemesForSurvey, updateHiddenThemesForSurvey]);

  useEffect(() => {
    async function updatePreparedHistoryStats() {
      if (!survey) {
        setPreparedHistoryStats(null);
        return;
      }
      function createDataset(label, backgroundColor) {
        return {
          label: label,
          borderRadius: 4,
          data: [],
          backgroundColor: backgroundColor,
          borderWidth: 0,
          borderColor: 'rgba(0, 0, 255, 1)'
        };
      }
      try {
        const dailyItems = survey.statsEntriesDaily?.items;
        const preparedHistoryStats = {
          daysChartEntryData: {},
          daysChartJsData: {
            labels: [],
            datasets: [
              createDataset('Negative', 'rgba(240, 100, 100, 1)'),
              createDataset('Neutral', 'rgba(170, 170, 170, 1)'),
              createDataset('Positive', 'rgba(100, 220, 100, 1)')
            ],
          },
          weeksChartEntryData: {},
          weeksChartJsData: {
            labels: [],
            datasets: [
              createDataset('Negative', 'rgba(240, 100, 100, 1)'),
              createDataset('Neutral', 'rgba(170, 170, 170, 1)'),
              createDataset('Positive', 'rgba(100, 220, 100, 1)')
            ],
          }
        };
        if (dailyItems) {
          const getLabelsQuery = /* GraphQL */ `
            query GetAllStatsDateLabels {
              getAllStatsDateLabels {
                daily {
                  date
                  label
                }
                weekly {
                  date
                  label
                }
              }
            }
          `;
          const labelsResponse = await API.graphql(graphqlOperation(getLabelsQuery, {}));
          const allLabels = labelsResponse.data.getAllStatsDateLabels;

          // keep only labels after the survey was created
          const surveyCreatedDate = new Date(survey.createdAt).getTime() / 1000;
          function trimToLaterThanDate(items, date) {
            const index = items.findIndex(elem => elem.date > date);
            return index >= 0 ? items.slice(index) : [];
          }
          allLabels.daily = trimToLaterThanDate(allLabels.daily, surveyCreatedDate);
          allLabels.weekly = trimToLaterThanDate(allLabels.weekly, surveyCreatedDate);

          const populatePreparedStats = (items, allLabels, entryData, chartJsData) => {
            const [negativeDataset, neutralDataset, positiveDataset] = chartJsData.datasets;
            for (const item of items) {
              entryData[item.label] = item;
            }

            for (const label of allLabels) {
              chartJsData.labels.push(label.label);
              const item = entryData[label.label];
              if (item) {
                // adjust pos/neu/neg counts to add up to the total number of responses
                const total = item.numThemeMatchesPos + item.numThemeMatchesNeu + item.numThemeMatchesNeg;
                positiveDataset.data.push(item.numThemeMatchesPos * item.numResponses / total);
                neutralDataset.data.push(item.numThemeMatchesNeu * item.numResponses / total);
                negativeDataset.data.push(item.numThemeMatchesNeg * item.numResponses / total);
              } else {
                positiveDataset.data.push(0);
                neutralDataset.data.push(0);
                negativeDataset.data.push(0);
              }
              // dataset.borderWidth.push(0);
            }
          };

          populatePreparedStats(dailyItems, allLabels.daily, preparedHistoryStats.daysChartEntryData, preparedHistoryStats.daysChartJsData)
          const weeklyItems = survey.statsEntriesWeekly?.items;
          if (weeklyItems) {
            populatePreparedStats(weeklyItems, allLabels.weekly, preparedHistoryStats.weeksChartEntryData, preparedHistoryStats.weeksChartJsData)
          }
          const newSelectedStatsWeek = preparedHistoryStats.weeksChartJsData.labels.length - 1;
          preparedHistoryStats.weeksChartJsData.datasets.forEach((dataset) => {
            dataset.borderWidth = calcBorderWidth(dataset.data.length, newSelectedStatsWeek);
          });
          setSelectedStatsWeek(newSelectedStatsWeek);
        }
        setPreparedHistoryStats(preparedHistoryStats);
      } catch (err) {
        console.log('error fetching survey stats:', err);
      }
    }
    updatePreparedHistoryStats();
  }, [survey, isUnowned]);

  const calcBorderWidth = (len, statsWeek) => {
    return Array.from({ length: len }, (v, i) => i === statsWeek ? 1 : 0 );
  };

  useEffect(() => {
    const chart = weeksChartRef.current;
    if (!chart) {
      return;
    }
    chart.data.datasets.forEach((dataset) => {
      dataset.borderWidth = calcBorderWidth(dataset.data.length, selectedStatsWeek);
    });
    chart.update();
  }, [selectedStatsWeek]);

  const updateThemeHidden = async (themeId, hidden) => {
    // there are no themes on unowned surveys
    verifyOwned();
    const updateSurveyThemeHiddenFromAdmin = /* GraphQL */ `
      mutation UpdateSurveyTheme(
        $input: UpdateSurveyThemeInput!
        $condition: ModelSurveyThemeConditionInput
      ) {
        updateSurveyTheme(input: $input, condition: $condition) {
          id
        }
      }
    `;
    await API.graphql(graphqlOperation(updateSurveyThemeHiddenFromAdmin, {
      input: {
        id: themeId,
        surveyID: hidden ? null : surveyId,
        surveyIDHidden: hidden ? surveyId : null
      }}));
  }

  const moveToVisibleOrHidden = async (themeId, toHidden) => {
    setHideInProgress(true);
    try {
      await updateThemeHidden(themeId, toHidden);
      await updateThemesForSurvey(null);
      await updateHiddenThemesForSurvey(null);
    } catch (err) {
      console.log('error updating theme:', err);
    } finally {
      setHideInProgress(false);
    }
  };

  const updateSurveyClosed = async (closed) => {
    // close button hidden for unowned
    verifyOwned();
    if (!survey) {
      console.log("survey not found, skipping update");
      return;
    }
    setCloseSurveyOpInProgress(true);
    try {
      const updateSurveyClosed = /* GraphQL */ `
        mutation UpdateSurvey(
          $input: UpdateSurveyInput!
          $condition: ModelSurveyConditionInput
        ) {
          updateSurvey(input: $input, condition: $condition) {
            id
          }
        }
      `;
      await API.graphql(graphqlOperation(updateSurveyClosed, {
        input: {
          id: surveyId,
          closed: closed
        }}));
    } finally {
      setCloseSurveyOpInProgress(false);
    }
  }
  const closeSurvey = async () => {
    try {
      await updateSurveyClosed(true);
      setSurvey({ ...survey, closed: true });
    } catch (err) {
      console.log('error closing survey:', err);
    }
  };
  const reopenSurvey = async () => {
    try {
      await updateSurveyClosed(false);
      setSurvey({ ...survey, closed: false });
    } catch (err) {
      console.log('error reopening survey:', err);
    }
  };
  const largestLengthCategory = useMemo(() => {
    if (!survey) {
      return null;
    }
    return LengthCategories.find(elem => elem.key === survey.largestLengthCategoryAllowed);
  }, [survey]);

  const handleUpdatingThemeVisibilityCheck = async (event) => {
    verifyOwned();
    const { checked } = event.target;
    if (checked !== survey.respondentsCanSeeThemes) {
      setUpdatingThemeVisibilityCheck(true);
      try {
        const updateSurveyThemeVisibility = /* GraphQL */ `
          mutation UpdateSurvey(
            $id: ID!,
            $respondentsCanSeeThemes: Boolean!
          ) {
            updateSurvey(input: {
              id: $id
              respondentsCanSeeThemes: $respondentsCanSeeThemes
            }) {
              id
            }
          }
        `;
        await API.graphql(graphqlOperation(updateSurveyThemeVisibility, {
            id: surveyId,
            respondentsCanSeeThemes: checked
          }));
        setSurvey({ ...survey, respondentsCanSeeThemes: checked });
      } finally {
        setUpdatingThemeVisibilityCheck(false);
      }
    }
  };

  const handleUpdatingSurveyDigestEmailCheck = async (event) => {
    verifyOwned();
    const { checked } = event.target;
    if (checked !== survey.sendEmailUpdates) {
      setUpdatingSurveyDigestEmailCheck(true);
      try {
        const updateSurveySendEmailUpdate = /* GraphQL */ `
          mutation UpdateSurvey(
            $id: ID!,
            $sendEmailUpdates: Boolean!
          ) {
            updateSurvey(input: {
              id: $id
              sendEmailUpdates: $sendEmailUpdates
            }) {
              id
            }
          }
        `;
        await API.graphql(graphqlOperation(updateSurveySendEmailUpdate, {
            id: surveyId,
            sendEmailUpdates: checked
          }));
        setSurvey({ ...survey, sendEmailUpdates: checked });
      } finally {
        setUpdatingSurveyDigestEmailCheck(false);
      }
    }
  };

  const onWeeksChartClick = (event, chartElements, chart) => {
    if (!chartElements || chartElements.length !== 1) {
      return;
    }
    setSelectedStatsWeek(chartElements[0].index);
  };

  // copied from example: https://www.chartjs.org/docs/latest/configuration/tooltip.html#external-custom-tooltips
  const statsChartTooltip = (context) => {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
      console.log("no tooltipEl");
      return;
    }

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }
    // console.log("tooltipModel: ", tooltipModel);

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }

    // Set Text
    if (tooltipModel.title[0] && preparedHistoryStats && preparedHistoryStats[historyViewMode + 'ChartEntryData']) {
        let innerHtml = '<tbody>';

        const label = tooltipModel.title[0];
        const data = preparedHistoryStats[historyViewMode + 'ChartEntryData'][label];
        if (data) {
          innerHtml += '<tr><td>Responses: ' + data.numResponses + '</td></tr>';
          innerHtml += '<tr><td>' +
            `Theme sentiment: <span style="color: green">${data.numThemeMatchesPos}</span> /
                     <span style="color: gray">${data.numThemeMatchesNeu}</span> /
                     <span style="color: red">${data.numThemeMatchesNeg}</span>` +
            '</td></tr>';
          if (data.topThemes && data.topThemes.length > 0) {
            innerHtml += '<tr><td>Themes of the ' + (historyViewMode === 'days' ? 'day' : 'week') + ':<br/>';
            innerHtml += '<table style="font-size: 9pt; margin-left: 3px">';
            data.topThemes.slice(0,3).forEach((theme) => {
              innerHtml += '<tr>';
              const themeSentimentColor = theme.themeSentiment === 'POS' ? 'green' : theme.themeSentiment === 'NEG' ? 'red' : 'gray';
              innerHtml += `<td style="text-align: left; color: ${themeSentimentColor}; padding: 1px 6px">` + theme.themeText + '</td>';
              innerHtml += '<td style="text-align: left; padding: 1px 6px">' + theme.numOccurrences + '</td>';
              innerHtml += '</tr>';
            });
            innerHtml += '</table></td></tr>';
          }
        }

        innerHtml += '</tbody>';

        let tableRoot = tooltipEl.querySelector('table');
        tableRoot.innerHTML = innerHtml;
    }

    const position = context.chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.padding = '3px';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.border = '1px solid black';
    tooltipEl.style.borderRadius = '5px';
    tooltipEl.style.backgroundColor = 'white';
  };

  const weeksChartRef = useRef(null);

  useEffect(() => {
    const tooltipEl = document.createElement('div');
    tooltipEl.style.zIndex = 9999;
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table style="font-size: 10pt"></table>';
    tooltipEl.style.opacity = 0;
    document.body.appendChild(tooltipEl);

    return () => {
      // Remove the tooltip div from the DOM when the component unmounts
      document.body.removeChild(tooltipEl);
    };
  }, []); // Empty dependency array ensures cleanup only happens once

  const getChartSuggestedYMax = () => {
    if (!preparedHistoryStats || !preparedHistoryStats[historyViewMode + 'ChartEntryData']) {
      return 10;
    }
    const numResponses = Object.values(preparedHistoryStats[historyViewMode + 'ChartEntryData']).map(elem => elem.numResponses);
    const max = Math.max(...numResponses);
    return Math.ceil(1.1 * max);
  };

  if (!survey) {
    return <div className="m-5">Loading...</div>;
  }

  return (
    <>
      <Breadcrumb className="m-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/a/surveys`}}>Surveys</Breadcrumb.Item>
        <Breadcrumb.Item active><b>Survey: {survey.surveyName}</b></Breadcrumb.Item>
      </Breadcrumb>

        {isUnowned && (
          <Alert variant="warning">
            <Link to={`/a/surveys/${surveyId}`}>Sign in</Link> to start using this survey.
          </Alert>
        )}
        {!isUnowned && survey.closed ? (
          <Alert variant="danger">
            <Alert.Heading>Survey Closed</Alert.Heading>
            No new responses will be accepted.&nbsp;
            <Button size="sm" variant="secondary" onClick={() => reopenSurvey()} disabled={closeSurveyOpInProgress} >Reopen</Button>
          </Alert>
         ) : (
          <LowResponseCreditsAlert />
         )}
        <Tabs
          defaultActiveKey="configuration"
          id="survey-details-tabs"
          transition={false}
          className="mx-5 my-4"
          variant="tabs" fill>
          <Tab eventKey="configuration" title={<span><IoSettings style={{ verticalAlign: 'text-bottom'}}/> Configuration</span>}>
            <Container className=''>
            <Row className="my-3 align-items-center">
              <Col className="fw-bold text-end col-3 col-md-2">Feedback about</Col>
              <Col>{survey.entityName}</Col>
            </Row>
            <Row className="my-3 align-items-center">
              <Col className="fw-bold text-end col-3 col-md-2">Survey headline</Col>
              <Col>{survey.surveyTitle}</Col>
            </Row>
            <Row className="my-3 align-items-start">
              <Col className="fw-bold text-end col-3 col-md-2">Survey prompt</Col>
              <Col>{survey.questionText.split('\n').map((item, key) => {
                return <span key={key}>{item}<br/></span>
              })}</Col>
            </Row>
            <Row className="my-3 align-items-center">
              <Col className="fw-bold text-end col-3 col-md-2">Themes visibility</Col>
              <Col>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Form.Check
                    type="checkbox"
                    id="sendEmailDigest"
                    name="sendEmailDigest"
                    label="Allow respondents to see all themes"
                    checked={survey.respondentsCanSeeThemes}
                    className="mb-0"
                    title="If checked, respondents will be able to see the list of themes extracted from survey responses. Only enable this if you want to give respondents visibility into what others are saying."
                    onChange={handleUpdatingThemeVisibilityCheck}
                    disabled={isUnowned || updatingThemeVisibilityCheck}
                  />
                  <span title="New feature Oct 2023" className="ms-1 pa-0"><IoFlame style={{ color: 'red', opacity: 0.7, verticalAlign: 'super'}} /></span>
                </div>
              </Col>
            </Row>
            <Row className="my-3 align-items-center">
              <Col className="fw-bold text-end col-3 col-md-2">Email summaries</Col>
              <Col>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Form.Check
                    type="checkbox"
                    id="sendEmailDigest"
                    name="sendEmailDigest"
                    label="Send me an email summary of new responses"
                    checked={survey.sendEmailUpdates}
                    className="mb-0"
                    onChange={handleUpdatingSurveyDigestEmailCheck}
                    disabled={isUnowned || updatingSurveyDigestEmailCheck}
                  />
                  <span title="New feature Sept 2023" className="ms-1 pa-0"><IoFlame style={{ color: 'red', opacity: 0.7, verticalAlign: 'super'}} /></span>
                </div>
              </Col>
            </Row>
            <Row className="my-3 align-items-center">
              <Col className="fw-bold text-end col-3 col-md-2">Credits used</Col>
              <Col>
                {survey.responseCreditsConsumed}
                {survey.freeResponseCreditsRemaining > 0 && (
                  <>&nbsp;({survey.freeResponseCreditsRemaining} free remaining)</>
                )}
                {!isUnowned && !survey.closed && (
                  <>
                  &nbsp;&nbsp;&nbsp;<Button size="sm" variant="secondary" onClick={() => closeSurvey()} disabled={closeSurveyOpInProgress}>Close Survey</Button>
                  </>
                )}
              </Col>
            </Row>
            <Row className="my-3 align-items-center">
              <Col className="fw-bold text-end col-3 col-md-2">Response size limit</Col>
              <Col>
                {largestLengthCategory.charLimit} characters
                ({largestLengthCategory.creditMaxDesc})
              </Col>
            </Row>
            {!isUnowned && (
              <Row className="my-4">
                <Col className="fw-bold text-end col-3 col-md-2"></Col>
                <Col>
                  <Button variant="danger" size="sm" onClick={() => setShowConfirmDelete(true)}>
                    Delete Survey
                  </Button>
                </Col>
              </Row>
            )}
          </Container>
          </Tab>
          <Tab eventKey="share" title={<span><IoShareSocial style={{ verticalAlign: 'text-bottom'}}/> Share</span>}>
            <Container className=''>
            <Row className="my-3 align-items-start">
              <Col className="fw-bold text-end col-3 col-md-2">Preview</Col>
              <Col>
                <span title="See how this survey will appear when viewed by your respondents."><Button variant="secondary" onClick={handlePreviewClick} disabled={isUnowned} size="sm" className="">Open in new tab</Button></span>
              </Col>
            </Row>
            <Row className="my-3 align-items-start">
              <Col className="fw-bold text-end col-3 col-md-2">URL</Col>
              <Col>
                <Stack direction="horizontal" gap={2}>
                  <FormControl
                    type="text"
                    value={survey.shareUrl}
                    onClick={(event) => event.target.select()}
                    readOnly
                    style={{width: "30em"}}
                    size="sm"
                  />
                  <span title="Copies a link to this survey to the clipboard. Text it, email it, or get it to your respondents however you like."><Button variant="secondary" onClick={handleCopyClick} disabled={isUnowned} size="sm">Copy</Button></span>
                  {urlCopied && (
                    <IoCheckmarkCircle style={{ color: 'green'}} className="me-1" />
                  )}
                </Stack>
                <Form.Text muted className='ms-1'>
                  Distribute this URL to people you'd like to respond to the survey.
                </Form.Text>

              </Col>
            </Row>
            <Row className="my-3 align-items-start">
              <Col className="fw-bold text-end col-3 col-md-2">QR Code</Col>
              <Col>
                <span title="Download a QR code linking to this survey. Add to documents or images for respondents to scan and respond to the survey."><Button variant="secondary" onClick={() => handleDownloadQRCode()} disabled={isUnowned || downloadInProgress} size="sm" className="">Download</Button></span>
                <span title="New feature Oct 2023" className="ms-1 pa-0"><IoFlame style={{ color: 'red', opacity: 0.7, verticalAlign: 'super'}} /></span>
              </Col>
            </Row>
            <Row className="my-3 align-items-start">
              <Col className="fw-bold text-end col-3 col-md-2">Flyer</Col>
              <Col>
                <span title="Download a flyer ready to print and post."><Button variant="secondary" onClick={() => setShowConfigureFlyer(true)} disabled={isUnowned || downloadInProgress} size="sm" className="">Download</Button></span>
                <span title="New feature Oct 2023" className="ms-1 pa-0"><IoFlame style={{ color: 'red', opacity: 0.7, verticalAlign: 'super'}} /></span>
              </Col>
            </Row>
          </Container>
          </Tab>

          <Tab eventKey="results" title={<span><IoAnalytics style={{ verticalAlign: 'text-bottom'}}/> Results</span>}>
            <Container className=''>

              <Row className="my-3 align-items-start">
                <Col className="fw-bold text-end col-3 col-md-2">Responses</Col>
                <Col className="col-10">
                  {survey.numResponses === 0 ? "0" : (
                    <>
                      <span>total: {survey.numResponses}</span>
                      <Link className="px-2" to={`/a/surveys/${surveyId}/responses`}>list</Link>
                    </>
                  )}
                </Col>
              </Row>

              <Row className="my-3 align-items-start">
                <Col className="fw-bold text-end col-3 col-md-2">History</Col>
                <Col className="justify-content-center col-10">
                  {preparedHistoryStats == null ? <p><em>(loading)</em></p> :

                    <Tabs
                      id="survey-results-history-mode-tabs"
                      activeKey={historyViewMode}
                      onSelect={(k) => setHistoryViewMode(k)}
                      transition={false}
                      className=""
                      variant="underline" >
                      <Tab eventKey="weeks" title="Weeks" disabled={!preparedHistoryStats?.weeksChartJsData}>
                        <Container className='px-0 pb-2'>
                          <Row className="">
                            {preparedHistoryStats.weeksChartJsData.labels.length === 0 ? (
                              <p><em>(no data yet)</em></p>
                            ) : (
                              <>
                                <Col className="col-12 col-md-5" style={{ minHeight: '18em', maxHeight: '18em' }}>
                                  <Bar
                                    ref={weeksChartRef}
                                    data={preparedHistoryStats.weeksChartJsData}
                                    options={{
                                      responsive: true,
                                      maintainAspectRatio: false,
                                      animation: false,
                                      scales: {
                                        y: {
                                          suggestedMax: getChartSuggestedYMax(),
                                          beginAtZero: true,
                                          stacked: true
                                        },
                                        x: {
                                          stacked: true
                                        }
                                      },
                                      onClick: onWeeksChartClick,
                                      plugins: {
                                        tooltip: {
                                          enabled: false,
                                          external: statsChartTooltip
                                        }
                                      },
                                    }} />
                                </Col>
                                <Col className="col-12 col-md-7">
                                  <div className="my-2" style={{ overflowY: 'auto', minHeight: '13em', maxHeight: '13em' }}>
                                    <p className="mb-0"><strong>{preparedHistoryStats.weeksChartJsData.labels[selectedStatsWeek]}</strong></p>
                                    <p>
                                      <small>{preparedHistoryStats.weeksChartEntryData[preparedHistoryStats.weeksChartJsData.labels[selectedStatsWeek]]?.textSummary ?? "(no summary)"}</small>
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-start">
                                    <ButtonGroup size="sm">
                                      <Button variant="secondary" onClick={() => incSelectedStatsWeek(-1)}><IoCaretBack /></Button>
                                      <Button variant="secondary" onClick={() => incSelectedStatsWeek(1)}><IoCaretForward/></Button>
                                    </ButtonGroup>
                                  </div>
                                </Col>
                              </>
                            )}
                          </Row>
                        </Container>
                      </Tab>

                      <Tab eventKey="days" title="Days" disabled={!preparedHistoryStats?.daysChartJsData}>
                        <Container className='px-0 pb-2'>
                          <Row className="">
                            {preparedHistoryStats.daysChartJsData.labels.length === 0 ? (
                              <Col className="col-12">
                                <em>(no data yet)</em>
                              </Col>
                            ) : (
                              <Col className="col-12" style={{ minHeight: '18em', maxHeight: '18em' }}>
                                <Bar
                                data={preparedHistoryStats.daysChartJsData}
                                options={{
                                  responsive: true,
                                  maintainAspectRatio: false,
                                  animation: false,
                                  scales: {
                                    y: {
                                      suggestedMax: getChartSuggestedYMax(),
                                      beginAtZero: true,
                                      stacked: true,
                                      backgroundColor: null
                                    },
                                    x: {
                                      stacked: true
                                    }
                                  },
                                  // omitting this causes it to use the callback from the other chart??
                                  onClick: null,
                                  plugins: {
                                    tooltip: {
                                      enabled: false,
                                      external: statsChartTooltip
                                    },
                                    legend: {
                                      display: false
                                    }
                                  }
                                }}
                                />
                              </Col>
                            )}
                          </Row>
                        </Container>

                      </Tab>
                    </Tabs>
                  }
                </Col>
              </Row>

              <Row className="my-3 align-items-start">
                <Col className="fw-bold text-end col-3 col-md-2">Themes</Col>
                <Col>
                  {themes == null ? <p>(loading)</p> : (
                      <>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th className="p-1 text-center align-middle"></th>
                              <th className="p-1 text-end align-middle">Occurrences</th>
                              <th className="p-1 text-end align-middle">Occ. since last comment</th>
                              <th className="p-1 text-end align-middle">Comments</th>
                              <th className="p-1 text-center align-middle"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {themes.orderedItems.length === 0 ? (
                              <tr key={0}>
                                <td className="p-1 align-middle">
                                  <em>(no response themes identified yet)</em>
                                </td>
                                <td className="p-1 text-end align-middle"></td>
                                <td className="p-1 text-end align-middle"></td>
                                <td className="p-1 text-end align-middle"></td>
                                <td className="p-1 text-center align-middle"></td>
                              </tr>
                            ) : (
                              themes.orderedItems.map((theme, index) => (
                                <tr key={index} style={{ backgroundColor: getRowBgColorAndIcon(theme.sentiment)[0] }}>
                                  <td className="p-1 align-middle">
                                    <Link to={`/a/surveys/${surveyId}/themes/${theme.id}`} className="p-1 text-start">{theme.text}</Link>&nbsp;&nbsp;
                                    {getRowBgColorAndIcon(theme.sentiment)[1]}
                                  </td>
                                  <td className="p-1 text-end align-middle">{theme.numOccurrences}</td>
                                  <td className="p-1 text-end align-middle">{(theme.comments && theme.comments.length > 0) ? theme.numOccurrencesSinceLastComment : "-"}</td>
                                  <td className={"p-1 text-end align-middle" + ((theme.comments && theme.comments.length > 0) ? "" : " text-danger")}>{theme.comments ? theme.comments.length : "0"}</td>
                                  <td className="p-1 text-center align-middle"><span title="Hides theme from this list and from view by respondents (if themes visibility is enabled)"><Button size="sm" variant="outline-secondary" disabled={hideInProgress} onClick={() => moveToVisibleOrHidden(theme.id, true)}>hide</Button></span></td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                        {(!themes.isFirstPage || themes.nextToken !== null) && (
                          <div className="d-flex justify-content-end">
                            <ButtonGroup size="">
                              <Button variant="secondary" disabled={themes.isFirstPage} onClick={() => updateThemesForSurvey(null)}><IoPlayBack /></Button>
                              <Button variant="secondary" disabled={themes.nextToken === null || themesHasEmptyLastPage} onClick={() => updateThemesForSurvey(themes.nextToken)}><IoCaretForward/></Button>
                            </ButtonGroup>
                          </div>
                        )}
                      </>
                  )}
                  {hiddenThemes != null && hiddenThemes.items.length > 0 && (
                    <Accordion className="my-4">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Hidden</Accordion.Header>
                        <Accordion.Body>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th className="p-1 text-center align-middle"></th>
                                <th className="p-1 text-end align-middle">Occurrences</th>
                                <th className="p-1 text-end align-middle">Occ. since last comment</th>
                                <th className="p-1 text-end align-middle">Comments</th>
                                <th className="p-1 text-center align-middle"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {hiddenThemes.items.map((theme, index) => (
                                <tr key={index} style={{ backgroundColor: getRowBgColorAndIcon(theme.sentiment)[0] }}>
                                  <td className="p-1 align-middle">
                                    <Link to={`/a/surveys/${surveyId}/themes/${theme.id}`} className="p-1 text-start">{theme.text}</Link>&nbsp;&nbsp;
                                    {getRowBgColorAndIcon(theme.sentiment)[1]}
                                  </td>
                                  <td className="p-1 text-end align-middle">{theme.numOccurrences}</td>
                                  <td className="p-1 text-end align-middle">{(theme.comments && theme.comments.length > 0) ? theme.numOccurrencesSinceLastComment : "-"}</td>
                                  <td className={"p-1 text-end align-middle" + ((theme.comments && theme.comments.length > 0) ? "" : " text-danger")}>{theme.comments ? theme.comments.length : "0"}</td>
                                  <td className="p-1 text-center align-middle"><Button size="sm" variant="outline-info" disabled={hideInProgress} onClick={() => moveToVisibleOrHidden(theme.id, false)}>unhide</Button></td>
                                </tr>
                              )) }
                            </tbody>
                          </table>
                          {(!hiddenThemes.isFirstPage || hiddenThemes.nextToken !== null) && (
                            <div className="d-flex justify-content-end">
                              <ButtonGroup size="">
                                <Button variant="secondary" disabled={hiddenThemes.isFirstPage} onClick={() => updateHiddenThemesForSurvey(null)}><IoPlayBack /></Button>
                                <Button variant="secondary" disabled={hiddenThemes.nextToken === null || hiddenThemesHasEmptyLastPage} onClick={() => updateHiddenThemesForSurvey(hiddenThemes.nextToken)}><IoCaretForward/></Button>
                              </ButtonGroup>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}
                </Col>
              </Row>

            </Container>
          </Tab>
        </Tabs>

        <br/><br/><br/><br/>
      <Modal show={showConfirmDelete} onHide={() => setShowConfirmDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete survey "{survey.surveyName}" and all of its responses and associated data?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="me-2" onClick={() => setShowConfirmDelete(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleDelete(surveyId)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConfigureFlyer} onHide={() => setShowConfigureFlyer(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Configure Flyer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Check
            type="checkbox"
            id="setFlyerIncludeQuestionText"
            name="setFlyerIncludeQuestionText"
            label="Include respondent instructions"
            checked={flyerIncludeQuestionText}
            className="mb-0"
            onChange={(event) => setFlyerIncludeQuestionText(event.target.checked)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="me-2" onClick={() => setShowConfigureFlyer(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleFlyer()}>
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SurveyDetails;
