import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { Heading, Text } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div>
      <Navbar bg="light" className="px-3" expand="md">
        <Navbar.Brand href="/">ActionaBull<sup><small style={{ color: '#999999' }}>(beta)</small></sup> <span style={{ position: 'relative', top: '-1px' }}>🐂</span></Navbar.Brand>
      </Navbar>
      <Container className="my-5">
        <Heading level={1}>Privacy Policy</Heading>

        <Text className='my-4'>
          Effective Date: July 30, 2023
        </Text>
        <Text className='my-4'>
          This Privacy Policy describes how ReadyRickshaw, LLC ("we," "us," or "our") collects, uses, and discloses information when you use the ActionaBull website and services (collectively, the "Services"). By using our Services, you agree to the collection, use, and disclosure of information in accordance with this Privacy Policy.
        </Text>

        <Heading level={2}>
          Information We Collect
        </Heading>
        <Text className='my-4'>
          <ul>
            <li>Account Information: When you create an account or make purchases, we may collect personal information such as your name, email address, and payment information.</li>
            <li>Survey Responses: We collect the text provided by respondents to our publishers' surveys.</li>
          </ul>
        </Text>
        <Heading level={2}>
          How We Use Your Information
        </Heading>
        <Text className='my-4'>
          <ul>
            <li>To provide our Services: We use your information to provide and enhance our Services, including analyzing and improving the user experience, providing support, and processing your payments.</li>
            <li>Communication: We use your information to respond to your inquiries, send notifications, and for other customer service purposes.</li>
            <li>Security: We use your information to protect our Services, our users, and ourselves, including detecting fraud and abuse, and enforcing our Terms of Service.</li>
          </ul>
        </Text>
        <Heading level={2}>
          How We Share Your Information
        </Heading>
        <Text className='my-4'>
          <ul>
            <li>With publishers: We share your survey responses, and any themes extracted from those responses, with the publisher who created the survey.</li>
            <li>With other respondents: Some information extracted from your survey responses may become visible to other users, especially with those who submit similar responses to the same survey. Do not include private information in survey responses.</li>
            <li>For legal reasons: We may disclose your information if we believe it's necessary to comply with applicable law, enforce our legal rights, or protect our Services and users.</li>
          </ul>

          We will not share your information for any other purposes without your consent.
        </Text>
        <Heading level={2}>
          Security
        </Heading>
        <Text className='my-4'>
          We take reasonable measures to help protect your information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.
        </Text>
        <Heading level={2}>
          Changes to This Privacy Policy
        </Heading>
        <Text className='my-4'>
          We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, providing you with additional notice.
        </Text>
        <Heading level={2}>
          Contact Us
        </Heading>
        <Text className='my-4'>
          If you have any questions about this Privacy Policy, please contact us at contact@readyrickshaw.com.
        </Text>
      </Container>
      <footer style={{ backgroundColor: 'black', fontSize: 'small', color: '#aaa', textAlign: 'center', padding: '.6rem', position: 'fixed', bottom: '0', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <div><a href="https://actionabull.com">ActionaBull</a> &copy; ReadyRickshaw, LLC</div>
        <div><Link to={'/privacy'}>privacy</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/terms'}>terms</Link></div>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;
