import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonGroup, Button, Breadcrumb, Container, Table, Row, Col } from 'react-bootstrap';
import { API, graphqlOperation } from 'aws-amplify'
import { IoPlayBack, IoCaretForward, IoCheckmarkSharp, IoFlame } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import { downloadResponses } from '../../graphql/queries';
import { getCreditsForCategory } from '../../utils';

function SurveyResponses() {
  const { surveyId } = useParams();
  const [surveyContext, setSurveyContext] = useState(null);
  const [responses, setResponses] = useState([]);
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const navigate = useNavigate();

  async function updateResponsesForSurvey(surveyId, nextToken) {
    const responsesBySurveyID = /* GraphQL */ `
      query SurveyResponsesBySurveyID(
        $surveyID: ID!
        $nextToken: String
      ) {
        surveyResponsesBySurveyIDAndProcessedAt(
          surveyID: $surveyID
          sortDirection: DESC
          limit: 15
          nextToken: $nextToken
        ) {
          items {
            id
            rawResponse
            processedAt
            lengthCategory
            comments {
              createdAt
            }
            respondentReceivingEmailUpdates
          }
          nextToken
        }
      }
    `;
    const responsesResponse = await API.graphql(graphqlOperation(responsesBySurveyID, {
      surveyID: surveyId,
      nextToken: nextToken
    }))
    const responsesData = responsesResponse.data.surveyResponsesBySurveyIDAndProcessedAt;
    responsesData.isFirstPage = nextToken === null
    setResponses(responsesData);
  }

  useEffect(() => {
    const getSurvey = /* GraphQL */ `
      query GetSurvey($id: ID!) {
        getSurvey(id: $id) {
          id
          surveyName
          numResponses
          nextAllowedResponsesExportDate
        }
      }`;
    async function fetchSurvey() {
      try {
        const surveyData = await API.graphql(graphqlOperation(getSurvey, {id: surveyId}))
        const survey = surveyData.data.getSurvey
        if (survey === null) {
          console.log("survey not found, redirecting to survey '" + surveyId + "'")
          navigate(`/a/surveys/${surveyId}`);
        }
        setSurveyContext(survey)

        await updateResponsesForSurvey(surveyId, null);

        document.title = `Responses for: ${survey.surveyName} - ActionaBull`
      } catch (err) {
        console.log('error fetching, redirecting to survey', err);
        navigate(`/a/surveys/${surveyId}`);
      }
    }
  
    fetchSurvey();
  }, [surveyId, navigate]);

  const abbreviate = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength - 3) + "...";
    }
    return str;
  }

  const getResponsesAsDownload = async () => {
    if (downloadInProgress) {
      return;
    }
    setDownloadInProgress(true);
    try {
      const csvResult = await API.graphql(graphqlOperation(
        downloadResponses,
        {surveyId: surveyId}));
      const csv = csvResult.data.downloadResponses;

      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
      downloadLink.download = `responses-${surveyContext.surveyName.replace(/[^\w]/g, '')}-${new Date().toISOString()}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      // push the next allowed export date out slightly to disable the button until the next page load
      setSurveyContext({...surveyContext, nextAllowedResponsesExportDate: Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 7});
    } catch (err) {
      console.log('error downloading responses', err);
      return;
    } finally {
      setDownloadInProgress(false);
    }
  };

  const isDownloadAllowed = surveyContext && (!surveyContext.nextAllowedResponsesExportDate ||
                               surveyContext.nextAllowedResponsesExportDate <= Math.floor(Date.now() / 1000));

  if (!surveyContext || !responses || !responses.items) {
    return <div className="m-5">Loading...</div>;
  }

  return (
    <>
      <Breadcrumb className="m-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/a/surveys`}}>Surveys</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/a/surveys/${surveyContext.id}`}}>{surveyContext.surveyName}</Breadcrumb.Item>
        <Breadcrumb.Item active><b>Responses ({surveyContext.numResponses})</b></Breadcrumb.Item>
      </Breadcrumb>

      <Container className='my-5'>
        <Row className="my-3 align-items-start">
          <Col className="col-md-10 offset-md-1">
            <>
              {responses.items.length === 0 ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Created</th>
                      <th>Response</th>
                      <th className="text-end">Comments</th>
                      <th className="text-center">Notifications</th>
                      <th className="text-end">Credits</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="align-items-center">
                      <td className="" colSpan="5">
                        &nbsp;
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Created</th>
                      <th>Response</th>
                      <th className="text-end">Comments</th>
                      <th className="text-center">Notifications</th>
                      <th className="text-end">Credits</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responses.items.map((response, index) => (
                      <tr key={index} className="align-items-center">
                        <td className="">
                          <span>{response.processedAt != null ? new Date(response.processedAt * 1000).toLocaleString() : "(pending)"}</span>
                        </td>
                        <td className="">
                          <Link to={`/a/surveys/${surveyContext.id}/responses/${response.id}`} className="text-start m-0 p-0">"{abbreviate(response.rawResponse, 50)}"</Link>
                        </td>
                        <td className="text-end">
                          <span>{response.comments ? response.comments.length : "0"}</span>
                        </td>
                        <td className="text-center">
                          <span>{response.respondentReceivingEmailUpdates && <IoCheckmarkSharp />}</span>
                        </td>
                        <td className="text-end">
                          <span>{getCreditsForCategory(response.lengthCategory)}</span>
                        </td>
                      </tr>
                    )) }
                  </tbody>
                </Table>
              )}
              {(!responses.isFirstPage || responses.nextToken !== null) && (
                <div className="d-flex justify-content-end">
                  <ButtonGroup size="">
                    <Button variant="secondary" disabled={responses.isFirstPage} onClick={() => updateResponsesForSurvey(surveyId, null)}><IoPlayBack /></Button>
                    <Button variant="secondary" disabled={responses.nextToken === null} onClick={() => updateResponsesForSurvey(surveyId, responses.nextToken)}><IoCaretForward/></Button>
                  </ButtonGroup>
                </div>
              )}
            </>
          </Col>
        </Row>
        <Row className="my-3 align-items-start">
          <Col className="col-md-10 offset-md-1">
            <span title={isDownloadAllowed ? "Download all results in csv format" : "Disabled because results have been downloaded recently"}><Button variant="outline-secondary" size="sm" disabled={downloadInProgress || !isDownloadAllowed} onClick={() => getResponsesAsDownload()}>Download all</Button></span>
            <span title="New feature Sept 2023" className="ms-1"><IoFlame style={{ color: 'red', opacity: 0.7, verticalAlign: 'super'}} /></span>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SurveyResponses;
