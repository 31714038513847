import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Table, Badge } from 'react-bootstrap';
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { Link } from 'react-router-dom';
import LowResponseCreditsAlert from '../../components/LowResponseCreditsAlert';
import { takeOwnershipOfSurvey } from '../../graphql/mutations'
import { unownedSurveyIdLocalStorageKey } from '../../utils';

function Surveys() {
  const [surveys, setSurveys] = useState([]);

  async function fetchSurveys() {
    const unownedSurveyId = localStorage.getItem(unownedSurveyIdLocalStorageKey);
    if (unownedSurveyId != null) {
      // user is signed in and there is a survey they created before being authenticated
      const resultObj = await API.graphql(graphqlOperation(takeOwnershipOfSurvey, {surveyId: unownedSurveyId}));
      const result =  resultObj.data.takeOwnershipOfSurvey;
      if (result != null) {
        if (result.success) {
          console.log("took ownership of survey: " + result.note);
          localStorage.removeItem(unownedSurveyIdLocalStorageKey);
        } else {
          console.log("failed to take ownership of survey: " + result.note);
        }
      } else {
        console.log("failed to take ownership of survey: ", resultObj);
      }
    }
    try {
      const surveysBySurveyOwnerQuery = /* GraphQL */ `
        query SurveysBySurveyOwner(
          $surveyOwner: String!
        ) {
          surveysBySurveyOwnerAndCreatedAt(
            surveyOwner: $surveyOwner
            sortDirection: DESC
          ) {
            items {
              id
              surveyName
              numResponses
              responseCreditsConsumed
              freeResponseCreditsRemaining
              closed
            }
          }
        }
      `;
      Auth.currentAuthenticatedUser()
        .then(async currentUser => {
          const surveyData = await API.graphql(graphqlOperation(surveysBySurveyOwnerQuery, {surveyOwner: currentUser.username}))
          const surveys = surveyData.data.surveysBySurveyOwnerAndCreatedAt.items
          setSurveys(surveys)
          document.title = `My surveys - ActionaBull`
        })
        .catch((err) => console.log("Error fetching",err));
    } catch (err) { console.log('error fetching surveys',err) }
  }

  useEffect(() => {
    fetchSurveys();
  }, []);

  return (
    <Container className="my-5">
      <LowResponseCreditsAlert />
      <Row>
        <Col>
          <h1>Surveys</h1>
        </Col>
        <Col className="text-end">
          <Link to="/a/surveys/new">
            <Button variant="primary">Add Survey</Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th className="text-end">Responses</th>
                <th className="text-end">Credits used</th>
              </tr>
            </thead>
            <tbody>
              {surveys.map(survey => (
                <tr key={survey.id}>
                  <td>
                    <Link to={`/a/surveys/${survey.id}`}>{survey.surveyName}</Link>
                    {survey.closed && (
                      <Badge className="ms-2" bg="dark">Closed</Badge>
                    )}
                  </td>
                  <td className="text-end">{survey.numResponses}</td>
                  <td className="text-end">
                    {survey.responseCreditsConsumed}
                    {survey.freeResponseCreditsRemaining > 0 && (
                      <>&nbsp;({survey.freeResponseCreditsRemaining} free remaining)</>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

    </Container>
  );
}

export default Surveys;

