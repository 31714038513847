import React from 'react';
import { useState, useEffect } from 'react';
import { createSurveyObj } from '../../graphql/mutations'
import { getNumFreeCreditsPerSurvey } from '../../graphql/queries'
import { useNavigate } from 'react-router-dom';
import { Form, Alert, Container } from 'react-bootstrap';
import { LengthCategories, unownedSurveyIdLocalStorageKey,
         isCurrentUserAuthenticated, graphqlQueryAllowingBothLoginStates, graphqlQueryAllowingBothLoginStatesForState } from '../../utils';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import gtag from 'ga-gtag'


function CreateSurvey() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [numFreeCreditsPerSurvey, setNumFreeCreditsPerSurvey] = useState(0);
  const [createError, setCreateError] = useState('');
  const [surveyData, setSurveyData] = useState({
    surveyName: '',
    entityName: '',
    surveyTitle: '',
    questionText: '',
    largestLengthCategoryAllowed: 'UP_TO_300_CHARS',
    sendEmailUpdates: true,
    respondentsCanSeeThemes: false
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    setSurveyData({ ...surveyData, [name]: newValue });
  };

  useEffect(() => {
    document.title = `Create new survey - ActionaBull`
    const fetchNumFreeCreditsPerSurvey = async () => {
      try {
        const response = await graphqlQueryAllowingBothLoginStates(getNumFreeCreditsPerSurvey);
        setNumFreeCreditsPerSurvey(response.data.getNumFreeCreditsPerSurvey);
      } catch (err) {
        console.log("Error getting num free credits per survey", err);
        return;
      }
    }
    fetchNumFreeCreditsPerSurvey();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    if (isSubmitting) {
      return; // Prevent multiple submissions while waiting for response
    }

    setIsSubmitting(true);

    try {
      const recaptchaToken = await executeRecaptcha('submit');
      const isAuthenticated = await isCurrentUserAuthenticated();
      const response = await graphqlQueryAllowingBothLoginStatesForState(
        createSurveyObj,
        {
          details: surveyData,
           recaptchaToken: recaptchaToken
        },
        isAuthenticated);
      const newSurveyId = response.data.createSurveyObj;
      if (isAuthenticated) {
        navigate(`/a/surveys/${newSurveyId}`);
      } else {
        // console.log('calling gtag for new user');
        // gtag is defined in the head block
        gtag('event', 'conversion', {'send_to': 'AW-1060181276/yVZwCJz1wuEYEJyqxPkD'});
        localStorage.setItem(unownedSurveyIdLocalStorageKey, newSurveyId);
        navigate(`/a/surveysu/${newSurveyId}`);
      }
    } catch (err) {
      console.log('error creating survey', err)
      setCreateError(err.errors[0].message)
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container className="my-5">
      <h1>Create a Survey</h1>
      <p className="lead">Once your survey is created, you can share the link to start collecting responses.</p>
      <Form onSubmit={handleSubmit}>
        <Form.Group className='my-4'>
          <Form.Label className='mb-1' htmlFor="feedback_for">Name your new survey:</Form.Label>
          <input
            type="text"
            className="form-control"
            id="surveyName"
            name="surveyName"
            placeholder="Example: My User Experience Survey #1"
            value={surveyData.surveyName}
            onChange={handleChange}
            required
            maxLength="150"
          />
          <Form.Text muted className='ms-1'>
            This is for your reference. Respondents will not see it.
          </Form.Text>
        </Form.Group>
        <Form.Group className='my-4'>
          <Form.Label className='mb-1' htmlFor="feedback_for">Survey will be collecting feedback about:</Form.Label>
          <input
            type="text"
            className="form-control"
            id="entityName"
            name="entityName"
            placeholder="Example: Maddy's Muffins"
            value={surveyData.entityName}
            onChange={handleChange}
            required
            maxLength="150"
          />
        </Form.Group>
        <Form.Group className='my-4'>
          <Form.Label className='mb-1' htmlFor="name">Survey headline:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Example: Tell us about your recent experience at Maddy's Muffins"
            id="surveyTitle"
            name="surveyTitle"
            value={surveyData.surveyTitle}
            onChange={handleChange}
            required
            maxLength="150"
          />
        </Form.Group>
        <Form.Group className='my-4'>
          <Form.Label className='mb-1' htmlFor="name">Provide some instructions to the respondent:</Form.Label>
          <textarea
            className="form-control"
            id="questionText"
            name="questionText"
            placeholder='Example: Please tell us about your experience today. We appreciate your feedback!'
            value={surveyData.questionText}
            onChange={handleChange}
            required
            maxLength="300"
          />
        </Form.Group>
        <Form.Group className='my-4'>
          <Form.Label className='mb-1' htmlFor="name">Response size limit:</Form.Label>
          <Form.Select
            id="largestLengthCategoryAllowed"
            name="largestLengthCategoryAllowed"
            value={surveyData.largestLengthCategoryAllowed}
            onChange={handleChange}
          >
            {LengthCategories.map((category, index) => (
              <option key={index} value={category.key}>{category.charLimit} characters ({category.creditMaxDesc})</option>
            ))}
          </Form.Select>
          <Form.Text muted className='ms-1'>
            Responses you receive will consume credits depending on their length. Each survey includes {numFreeCreditsPerSurvey} free credits.
          </Form.Text>
        </Form.Group>
        <Form.Group className='my-4'>
          <Form.Check
            type="checkbox"
            id="respondentsCanSeeThemes"
            name="respondentsCanSeeThemes"
            label="Allow respondents to see all themes"
            checked={surveyData.respondentsCanSeeThemes}
            onChange={handleChange}
            title="If checked, respondents will be able to see the list of themes extracted from survey responses. Only enable this if you want to give respondents visibility into what everyone else is saying."
          />
        </Form.Group>
        <Form.Group className='my-4'>
          <Form.Check
            type="checkbox"
            id="sendEmailUpdates"
            name="sendEmailUpdates"
            label="Send me email updates about this survey"
            checked={surveyData.sendEmailUpdates}
            onChange={handleChange}
          />
        </Form.Group>
        
        {createError && (
          <Form.Group className='my-4'>
            <Alert variant="danger">{createError}</Alert>
          </Form.Group>
        )}

        <Form.Group style={{ height: '90px' /* make sure there's space at the bottom to scroll */ }}>
          <button type="submit" className="btn btn-primary me-2" disabled={!executeRecaptcha || isSubmitting}>
          {!executeRecaptcha ? '(loading...)' : isSubmitting ? 'Creating...' : 'Create Survey'}
          </button>
          <button type="button" className="btn btn-secondary" onClick={() => window.history.back()}>
            Cancel
          </button>
        </Form.Group>
      </Form>

    </Container>
  );
}

export default CreateSurvey;
