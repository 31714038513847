import React from 'react';

import { Link } from 'react-router-dom';

// Based on https://startbootstrap.com/theme/new-age

const DogfoodSurveyId = process.env.REACT_APP_DOGFOOD_SURVEY_ID || 'd3a5894f-743a-4b7e-8a90-ed474173b922';

function About() {
  return (
    <>
      {/* Mashead header */}
      <header className="masthead py-2 py-md-5">
          <div className="container px-2">
              <div className="row gx-2 align-items-center">
                  <div className="col-12 col-lg-5 pb-3 pb-md-5 pt-5">
                      {/* <!-- Mashead text and app badges--> */}
                      <div className="my-5 mb-lg-0 text-center text-md-start">
                          <h1 className="display-1 lh-1 mb-3">Intelligent surveys.</h1>
                          <p className="lead fw-normal text-muted mb-5">Not just a survey, the start of a conversation. Elevate your customer engagement.</p>
                          <Link className="btn btn-dark py-3 px-4 rounded-pill" to={`/a/surveys/new`}>Create a survey now</Link>
                          <p className="text-muted ms-0 ms-md-4" style={{fontSize: 'smaller'}}>estimated time: 2 minutes</p>
                      </div>
                  </div>
                  <div className="col-12 col-lg-7 pa-3">
                      <video muted={true} autoPlay={true} loop={true} playsinline={true} style={{maxWidth: "100%"}}><source src="/demo.mp4" type="video/mp4" /></video>
                  </div>
              </div>
          </div>
      </header>

      <section id="features">
            <div className="container px-5 gy-5">
                <div className="row gx-5 mb-5 align-items-center">
                    <div className="col-12 mb-5 mb-lg-0">
                        <div className="container-fluid px-5">
                            <div className="row gx-5">
                                <div className="col-md-6 mb-5">
                                    <div className="text-center">
                                        <i className="bi-cursor-text icon-feature text-gradient d-block mb-3"></i>
                                        <h3 className="font-alt">Freeform Text</h3>
                                        <p className="text-muted mb-0">Still using interrogation-style surveys? Just let your customers speak, then listen.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-5">
                                    <div className="text-center">
                                        <i className="bi-lightbulb icon-feature text-gradient d-block mb-3"></i>
                                        <h3 className="font-alt">Automatic Summaries</h3>
                                        <p className="text-muted mb-0">Your surveys get responses, we send you executive summaries.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-5 mb-md-0">
                                    <div className="text-center">
                                        <i className="bi-repeat icon-feature text-gradient d-block mb-3"></i>
                                        <h3 className="font-alt">Interactive Feedback Loop</h3>
                                        <p className="text-muted mb-0">Chat with customers about their responses. Remind customers about you. Don't miss repeat buying opportunities.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-center">
                                        <i className="bi-stopwatch icon-feature text-gradient d-block mb-3"></i>
                                        <h3 className="font-alt">Effortless Survey Creation</h3>
                                        <p className="text-muted mb-0"><Link className="" to={`/a/surveys/new`}>Create your first survey</Link> in minutes.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <aside className="text-center bg-gradient-primary-to-secondary">
            <div className="container px-5">
                <div className="row gx-5 justify-content-center">
                    <div className="col-xl-8">
                        <div className="h2 fs-1 text-white mb-4">"Discover what matters to your customers. With ActionaBull, build stronger relationships and improve engagement."</div>
                    </div>
                </div>
            </div>
        </aside>

        <section id="howitworks">
            <div className="container px-5">
                <div className="row gx-5 align-items-center">
                    <div className="col-md-7 offset-md-2 col-12 order-lg-1 mb-5 mb-lg-0 ">
                        <h2 className="display-4 lh-1 mb-4">How it works</h2>
                        <ol>
                            <li className="my-4">
                                <h5 className="font-alt"><Link className="" to={`/a/surveys/new`}>Create your survey</Link>&nbsp;&nbsp;📝</h5>
                                Get started in just a couple of minutes.
                                </li>
                            <li className="my-4">
                                <h5 className="font-alt">Distribute the URL&nbsp;&nbsp;📤</h5>
                                Share it with your customers through your chosen channel.
                                </li>
                            <li className="my-4">
                                <h5 className="font-alt">Analyze responses&nbsp;&nbsp;📊</h5>
                                Our AI helps you identify significant points and sentiments.
                                </li>
                            <li className="my-4">
                                <h5 className="font-alt">Keep the conversation going&nbsp;&nbsp;💬</h5>
                                Comment and stay engaged with your customers for continued interaction.
                                </li>
                        </ol>
                    </div>
                </div>
                <div className="row align-items-center text-center mt-5">
                    <div className="col-12 text-center">
                      Want to do a good deed? <Link className="" to={`/s/${DogfoodSurveyId}`}>Go here</Link> and tell us your first impressions of this site.
                    </div>
                </div>
            </div>
        </section>

        <section className="cta">
            <div className="cta-content">
                <div className="container px-5">
                    <h2 className="text-white display-1 lh-1 mb-4">
                    Ready to connect to your customers?
                    </h2>
                    <Link className="btn btn-light py-3 px-4 rounded-pill" to={`/a/surveys/new`}>Create a survey</Link>
                </div>
            </div>
        </section>


    </>
  );
}

export default About;
