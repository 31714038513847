import { IoThumbsUp, IoThumbsDown } from "react-icons/io5";
import { BsFillEmojiNeutralFill } from "react-icons/bs";
import { Auth, graphqlOperation } from 'aws-amplify'
import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { getOrCreateCurrentUserSettings } from './graphql/mutations'
import gtag from 'ga-gtag'

// keep in sync with color/icon in emailManager
export const getRowBgColorAndIcon = (sentiment) => {
  if (sentiment === 'POS') {
    return ['rgba(0, 255, 0, 0.1)', <span title="Theme categorized as positive"><IoThumbsUp className="text-success mb-1" style={{ verticalAlign: 'middle' }} /></span>];
  } else if (sentiment === 'NEG') {
    return ['rgba(255, 0, 0, 0.1)', <span title="Theme categorized as negative"><IoThumbsDown className="text-danger mb-1" style={{ verticalAlign: 'middle' }} /></span>];
  } else {
    return ['white', <span title="Theme categorized as neither positive nor negative"><BsFillEmojiNeutralFill className="text-muted mb-1" style={{ verticalAlign: 'middle' }} /></span>];
  }
}

// keep this synced with the same enum in common.js and the enum in schema.graphql
export const LengthCategories = [
  {
    key: 'UP_TO_150_CHARS',
    charLimit: 150,
    credits: 1,
    creditMaxDesc: "each response consumes 1 credit"
  },
  {
    key: 'UP_TO_300_CHARS',
    charLimit: 300,
    credits: 2,
    creditMaxDesc: "each response consumes up to 2 credits"
  },
  {
    key: 'UP_TO_600_CHARS',
    charLimit: 600,
    credits: 3,
    creditMaxDesc: "each response consumes up to 3 credits"
  }
];

export const getCreditsForCategory = (lengthCategoryKey) => {
  return LengthCategories.find((lc) => lc.key === lengthCategoryKey).credits;
}

export const getOrCreateCurrentUserSettingsImpl = async (includePurchaseHistory) => {
    const userSettingsResponse = await API.graphql(graphqlOperation(
      getOrCreateCurrentUserSettings,
      {includePurchaseHistory: includePurchaseHistory}));
    // console.log('userSettingsResponse:', userSettingsResponse);
    let receivedUserSettings = userSettingsResponse.data.getOrCreateCurrentUserSettings;
    if (receivedUserSettings == null) {
      console.log('userSettingsResponse was null, trying again to recover from concurrent calls');
      const userSettingsResponse = await API.graphql(graphqlOperation(
        getOrCreateCurrentUserSettings,
        {includePurchaseHistory: includePurchaseHistory}));
      receivedUserSettings = userSettingsResponse.data.getOrCreateCurrentUserSettings;
    }
    if (receivedUserSettings.isNewlyCreated) {
      // console.log('calling gtag for new user');
      // gtag is defined in the head block
      gtag('event', 'conversion', {'send_to': 'AW-1060181276/qCebCJ_1wuEYEJyqxPkD'});
    }
    return receivedUserSettings;
}

export const isCurrentUserAuthenticated = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (err) {
    return false;
  }
};

export const graphqlQueryAllowingBothLoginStatesForState = async (query, variables, isUserAuthenticated) => {
  return await API.graphql({
    query: query,
    variables: variables,
    authMode: isUserAuthenticated ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM
  });
}

export const graphqlQueryAllowingBothLoginStates = async (query, variables) => {
  return await graphqlQueryAllowingBothLoginStatesForState(query, variables, await isCurrentUserAuthenticated());
};

export const abbreviate = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + "...";
  }
  return str;
}

export const unownedSurveyIdLocalStorageKey = 'unownedSurveyId';
