import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb, Container, Row, Col, ListGroup, Form, Stack, Button } from 'react-bootstrap';
import { graphqlOperation } from 'aws-amplify'
import { API } from '@aws-amplify/api';
import { Link, useNavigate } from 'react-router-dom';
import { getRowBgColorAndIcon } from '../../utils';
import { IoTrash } from "react-icons/io5";
import { LengthCategories } from '../../utils';
import { addPublisherCommentOnResponse, deleteLastPublisherCommentOnResponse } from '../../graphql/mutations'

const MAX_COMMENT_LEN = 200;

function SurveyResponseDetails() {
  const { surveyId, responseId } = useParams();
  const [survey, setSurvey] = useState(null);
  const [surveyResponse, setSurveyResponse] = useState(null);

  const [newComment, setNewComment] = useState("");
  const [commentError, setCommentError] = useState(null);
  const [changingComments, setChangingComments] = useState(false);

  const navigate = useNavigate();

  const abbreviate = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength - 3) + "...";
    }
    return str;
  }

  useEffect(() => {
    const getSurvey = /* GraphQL */ `
      query GetSurvey($id: ID!) {
        getSurvey(id: $id) {
          id
          surveyName
          numResponses
          createdAt
        }
      }
    `;
    async function fetchSurveyResponse() {
      try {
        const getSurveyResponseQuery = /* GraphQL */ `
          query GetSurveyResponse($id: ID!) {
            getSurveyResponse(id: $id) {
              id
              surveyID
              rawResponse
              processedAt
              commentVersion
              length
              lengthCategory
              comments {
                createdAt
                text
                commenter
              }
              points {
                items {
                  themeID
                  theme {
                    text
                    sentiment
                    numOccurrences
                    numOccurrencesSinceLastComment
                    comments {
                      text
                      createdAt
                    }
                  }
                }
              }
              respondentReceivingEmailUpdates
            }
          }
        `;
        const surveyResponseData = await API.graphql(graphqlOperation(getSurveyResponseQuery, {
          id: responseId
        }))
        const surveyResponse = surveyResponseData.data.getSurveyResponse;
        if (surveyResponse.surveyID !== surveyId) {
          console.log("surveyId mismatch, redirecting to survey '" + surveyResponse.surveyID + "' '" + surveyId + "'")
          navigate(`/a/surveys/${surveyId}`);
        }

        surveyResponse.orderedPoints = (surveyResponse && surveyResponse.points && surveyResponse.points.items) ?
            surveyResponse.points.items.sort((a, b) => {
              const theme_a = a.theme;
              const theme_b = b.theme;
              if (theme_b.numOccurrences !== theme_a.numOccurrences) {
                return theme_b.numOccurrences - theme_a.numOccurrences;
              }
              const effOslc_a = theme_a.comments && theme_a.comments.length > 0 ? theme_a.numOccurrencesSinceLastComment : Number.MAX_SAFE_INTEGER;
              const effOslc_b = theme_b.comments && theme_b.comments.length > 0 ? theme_b.numOccurrencesSinceLastComment : Number.MAX_SAFE_INTEGER;
              return effOslc_b - effOslc_a;
            }) :
            [];

        setSurveyResponse(surveyResponse)

        const surveyData = await API.graphql(graphqlOperation(getSurvey, {id: surveyId}))
        const survey = surveyData.data.getSurvey
        setSurvey(survey)

        document.title = `Response for ${survey.surveyName} - ActionaBull`
      } catch (err) {
        console.log('error fetching survey', err)
        navigate(`/a/surveys/${surveyId}`);
      }
    }
  
    fetchSurveyResponse();
  }, [surveyId, responseId, navigate]);

  const handleNewCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const addComment = async (event) => {
    event.preventDefault();

    setChangingComments(true);
    setCommentError(null);

    const newCommentText = newComment.trim();
    if (newCommentText.length === 0) {
      setCommentError("Comment cannot be empty");
      return;
    }
    if (newCommentText.length > MAX_COMMENT_LEN) {
      setCommentError("Comment cannot be longer than 200 characters");
      return;
    }

    try {
      const response = await API.graphql(graphqlOperation(addPublisherCommentOnResponse, {
        responseId: surveyResponse.id, comment: newCommentText, commentVersion: surveyResponse.commentVersion}));
      const updateResponse = response.data.addPublisherCommentOnResponse;
      if (updateResponse == null) {
        console.log("no response");
        setCommentError("An unexpected error occurred.");
        return;
      }
      setSurveyResponse(surveyResponse => ({ ...surveyResponse,
            commentVersion: updateResponse.commentVersion,
            comments: updateResponse.comments}));
      if (updateResponse.wasSuccessful) {
        setNewComment("");
      } else {
        setCommentError("There was an error adding the comment. Please try again.");
      }
    } catch (err) {
      console.log('error adding comment', err);
      setCommentError("There was an error adding the comment");
    } finally {
      setChangingComments(false);
    }
  }

  const deleteLastComment = async () => {
    setChangingComments(true);
    setCommentError(null);
    try {
      const response = await API.graphql(graphqlOperation(deleteLastPublisherCommentOnResponse, {
        responseId: surveyResponse.id, commentVersion: surveyResponse.commentVersion}));
      const updateResponse = response.data.deleteLastPublisherCommentOnResponse;
      if (updateResponse == null) {
        console.log("no response");
        setCommentError("An unexpected error occurred.");
        return;
      }
      setSurveyResponse(surveyResponse => ({ ...surveyResponse,
            comments: updateResponse.comments,
            commentVersion: updateResponse.commentVersion}));
      if (!updateResponse.wasSuccessful) {
        setCommentError("There was an error deleting the comment. Please try again.");
      }
    } catch (err) {
      console.log('error deleting comment', err)
      setCommentError("There was an error deleting the comment.");
    } finally {
      setChangingComments(false);
    }
  }

  const getNumCreditsConsumed = () => {
    if (!surveyResponse) {
      return -1;
    }
    return LengthCategories.find(elem => elem.key === surveyResponse.lengthCategory).credits;
  }

  if (!survey) {
    return <div className="m-5">Loading...</div>;
  }

  return (
    <>
      <Breadcrumb className="m-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/a/surveys`}}>Surveys</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/a/surveys/${survey.id}`}}>{survey.surveyName}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/a/surveys/${survey.id}/responses`}}>Responses ({survey.numResponses})</Breadcrumb.Item>
        <Breadcrumb.Item active><b>Response: "{abbreviate(surveyResponse.rawResponse, 40)}"</b></Breadcrumb.Item>
      </Breadcrumb>

      <Container className='my-5'>
        <Row className="my-4 align-items-center">
          <Col className="fw-bold text-end col-2">Submitted</Col>
          <Col>{surveyResponse.processedAt != null ? new Date(surveyResponse.processedAt * 1000).toLocaleString() : "(pending)"}</Col>
        </Row>
        <Row className="my-4 align-items-start">
          <Col className="fw-bold text-end col-2">Response</Col>
          <Col className="bg-light">
            {surveyResponse.rawResponse.split('\n').map((item, key, array) => {
              return (
                <span key={key}>
                  {item}
                  {key !== array.length - 1 && <br />}
                </span>
              )
            })}
          </Col>
        </Row>
        <Row className="my-4 align-items-start">
          <Col className="fw-bold text-end col-2">Credits used</Col>
          <Col>{getNumCreditsConsumed()}&nbsp;&nbsp;(response length: {surveyResponse.length} characters)</Col>
        </Row>
        <Row className="my-4 align-items-start">
          <Col className="fw-bold text-end col-2">Themes</Col>
          <Col className="">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="p-1 text-center"></th>
                  <th className="p-1 text-end">Occurrences</th>
                  <th className="p-1 text-end">Comments</th>
                </tr>
              </thead>
              <tbody>
                {surveyResponse.orderedPoints.map((point, index) => (
                  <tr key={index} style={{ backgroundColor: getRowBgColorAndIcon(point.theme.sentiment)[0] }}>
                    <td className="py-1">
                      <Link to={`/a/surveys/${survey.id}/themes/${point.themeID}`} className="p-1 text-start">{point.theme.text}</Link>&nbsp;&nbsp;
                      {getRowBgColorAndIcon(point.theme.sentiment)[1]}
                      {point.theme.surveyIDHidden != null && <em>&nbsp;&nbsp;(hidden)</em>}
                    </td>
                    <td className="py-1 text-end">{point.theme.numOccurrences}</td>
                    <td className="py-1 text-end">{point.theme.comments ? point.theme.comments.length : "0"}</td>
                  </tr>
                )) }
              </tbody>
            </table>
          </Col>
        </Row>
        <Row className="my-4 align-items-start">
          <Col className="fw-bold text-end col-2">
            Comments on this response
          </Col>
          <Col>
            <ListGroup>
              <ListGroup.Item className="p-2">
                <Form onSubmit={addComment}>
                  <Form.Group className='m-0'>
                    <Stack direction="horizontal" gap={2}>
                      <input
                        type="text"
                        className="form-control"
                        id="newComment"
                        name="newComment"
                        placeholder="Write your comment here"
                        value={newComment}
                        onChange={handleNewCommentChange}
                        required
                        maxLength={MAX_COMMENT_LEN + 10}
                        disabled={changingComments}
                      />
                      <button type="submit" className="btn btn-primary ms-auto" disabled={changingComments}>
                        Add
                      </button>
                    </Stack>
                  </Form.Group>
                </Form>
                {commentError && <div className="alert alert-danger mt-3" role="alert">{commentError}</div>}
              </ListGroup.Item>
              {surveyResponse.comments != null && surveyResponse.comments.map((comment, index) => (
                <ListGroup.Item key={index} className="py-1 px-2">
                  <Stack direction="horizontal" gap={3}>
                    <div>
                      {comment.text.split('\n').map((item, key) => (
                        <span key={key}>{item}<br /></span>
                      ))}
                    </div>
                    {index === 0 && comment.commenter === "PUBLISHER" && (
                      <Button className="ms-auto" variant="link" size="sm" onClick={() => {deleteLastComment()}}>
                        <IoTrash className="text-danger mb-1" />
                      </Button>
                    )}
                  </Stack>
                  <span className="small text-muted mt-0">{new Date(comment.createdAt).toLocaleString()} by {comment.commenter === "PUBLISHER" ? "you" : "respondent"}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
        <Row className="my-4 align-items-start">
          <Col className="fw-bold text-end col-2">Respondent receives notifications</Col>
          <Col>
            {surveyResponse.respondentReceivingEmailUpdates ? "Yes" : "No"}
          </Col>
        </Row>

      </Container>
    </>
  );
}

export default SurveyResponseDetails;
