import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonGroup, Button, Breadcrumb, Container, Row, Col, Stack, Form, ListGroup } from 'react-bootstrap';
import { graphqlOperation } from 'aws-amplify'
import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { IoPlayBack, IoCaretForward, IoTrash } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import { getRowBgColorAndIcon } from '../../utils';

function SurveyThemeDetails() {
  const { surveyId, themeId } = useParams();
  const [surveyContext, setSurveyContext] = useState(null);
  const [surveyTheme, setSurveyTheme] = useState(null);
  const [relatedResponses, setRelatedResponses] = useState(null);

  const [commentVersion, setCommentVersion] = useState(0);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [commentError, setCommentError] = useState(null);
  const [changingComments, setChangingComments] = useState(false);

  const navigate = useNavigate();

  async function fetchResponsesForTheme(themeId, nextToken) {
    const surveyResponsePointsByThemeID = /* GraphQL */ `
      query SurveyResponsePointsByThemeID(
        $themeID: ID!
        $limit: Int
        $nextToken: String
      ) {
        surveyResponsePointsByThemeIDAndCreatedAt(
          themeID: $themeID
          sortDirection: DESC
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            surveyResponseID
            responseExcerpt
            createdAt
          }
          nextToken
        }
      }
    `;
    const responsesResponse = await API.graphql(graphqlOperation(surveyResponsePointsByThemeID, {
      themeID: themeId,
      limit: 10,
      nextToken: nextToken
    }))
    const responsesData = responsesResponse.data.surveyResponsePointsByThemeIDAndCreatedAt;
    responsesData.isFirstPage = nextToken === null
    setRelatedResponses(responsesData);
  }

  useEffect(() => {
    const getSurveyTheme = /* GraphQL */ `
      query GetSurveyTheme($id: ID!) {
        getSurveyTheme(id: $id) {
          id
          surveyID
          surveyIDHidden
          sentiment
          text
          numOccurrences
          commentVersion
          comments {
            createdAt
            text
            commenter
          }
        }
      }`;
    const getSurvey = /* GraphQL */ `
      query GetSurvey($id: ID!) {
        getSurvey(id: $id) {
          id
          surveyName
        }
      }`;
    async function fetchSurveyTheme() {
      try {
        const surveyThemeData = await API.graphql(graphqlOperation(getSurveyTheme, {id: themeId}))
        const surveyTheme = surveyThemeData.data.getSurveyTheme;
        if (surveyTheme === null) {
          console.log("theme not found, redirecting to survey '" + surveyId + "'")
          navigate(`/a/surveys/${surveyId}`);
        }
        if (surveyTheme.surveyID != null) {
          if (surveyTheme.surveyIDHidden != null || surveyTheme.surveyID !== surveyId) {
            console.log(`unexpected survey ids for theme ${surveyTheme.id}, redirecting to survey ${surveyId}`);
            navigate(`/a/surveys/${surveyId}`);
          }
          surveyTheme.hidden = false;
        } else if (surveyTheme.surveyIDHidden != null) {
          if (surveyTheme.surveyID != null || surveyTheme.surveyIDHidden !== surveyId) {
            console.log(`unexpected survey ids for theme ${surveyTheme.id}, redirecting to survey ${surveyId}`);
            navigate(`/a/surveys/${surveyId}`);
          }
          surveyTheme.hidden = true;
        } else {
          console.log(`no survey id field set for theme ${surveyTheme.id}, redirecting to survey ${surveyId}`);
          navigate(`/a/surveys/${surveyId}`);
        }
        setSurveyTheme(surveyTheme)
        const surveyData = await API.graphql(graphqlOperation(getSurvey, {id: surveyId}))
        const survey = surveyData.data.getSurvey
        setSurveyContext(survey)

        fetchResponsesForTheme(themeId, null);

        setCommentVersion(surveyTheme.commentVersion);
        setComments(surveyTheme.comments || []);

        document.title = `Theme "${abbreviate(surveyTheme.text, 20)}" - ActionaBull`
      } catch (err) {
        console.log('error fetching, redirecting to survey', err);
        navigate(`/a/surveys/${surveyId}`);
      }
    }
  
    fetchSurveyTheme();
  }, [surveyId, themeId, navigate]);

  const abbreviate = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength - 3) + "...";
    }
    return str;
  }

  const addComment = async (event) => {
    event.preventDefault();

    setChangingComments(true);
    try {
      console.log("adding comment", newComment);
      const newCommentText = newComment.trim();
      if (newCommentText.length === 0) {
        setCommentError("Comment cannot be empty");
        return;
      }

      const newComments = [{
        createdAt: new Date().toISOString(),
        text: newCommentText,
        commenter: "PUBLISHER"
      }].concat(comments);
      
      const newCommentVersion = commentVersion + 1;

      const updateSurveyThemeQuery = /* GraphQL */ `
        mutation UpdateSurveyTheme(
          $input: UpdateSurveyThemeInput!
          $condition: ModelSurveyThemeConditionInput
        ) {
          updateSurveyTheme(input: $input, condition: $condition) {
            id
          }
        }
      `;

      await API.graphql({
        query: updateSurveyThemeQuery,
        variables: {
          input: {
            id: surveyTheme.id,
            commentVersion: newCommentVersion,
            comments: newComments,
            numOccurrencesSinceLastComment: 0
          },
          condition: {
            commentVersion: {
              eq: commentVersion
            }
          }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      });
      setComments(newComments);
      setCommentVersion(newCommentVersion);
      setNewComment("");
    } catch (err) {
      console.log('error adding comment', err);
      setCommentError("There was an error adding the comment");
    } finally {
      setChangingComments(false);
    }
  }

  const deleteComment = async (index) => {
    setChangingComments(true);
    try {
      console.log("deleting comment", comments[index]);

      if (index < 0 || index >= comments.length) {
        console.log("invalid index", index);
        return;
      }

      const newComments = comments.slice(0, index).concat(comments.slice(index + 1));
      
      const newCommentVersion = commentVersion + 1;

      const updateSurveyThemeQuery = /* GraphQL */ `
        mutation UpdateSurveyTheme(
          $input: UpdateSurveyThemeInput!
          $condition: ModelSurveyThemeConditionInput
        ) {
          updateSurveyTheme(input: $input, condition: $condition) {
            id
          }
        }
      `;

      await API.graphql({
        query: updateSurveyThemeQuery,
        variables: {
          input: {
            id: surveyTheme.id,
            commentVersion: newCommentVersion,
            comments: newComments
          },
          condition: {
            commentVersion: {
              eq: commentVersion
            }
          }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      });
      setComments(newComments);
      setCommentVersion(newCommentVersion);
    } catch (err) {
      console.log('error removing comment', err);
    } finally {
      setChangingComments(false);
    }
  }

  const handleNewCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const getNumOccurrencesText = () => {
    if (surveyTheme.comments && surveyTheme.comments.length > 0) {
      if (surveyTheme.numOccurrencesSinceLastComment > 0) {
        return <span>This theme has appeared <b>{surveyTheme.numOccurrencesSinceLastComment} time{surveyTheme.numOccurrencesSinceLastComment > 1 ? "s" : ""}</b> since your last comment, and {surveyTheme.numOccurrences} time{surveyTheme.numOccurrences > 1 ? "s" : ""} in total.</span>;
      } else {
        return <span>This theme has appeared <b>{surveyTheme.numOccurrences} time{surveyTheme.numOccurrences > 1 ? "s" : ""}</b>. It has not appeared since your last comment.</span>;
      }
    } else {
      return <span>This theme has appeared <b>{surveyTheme.numOccurrences} time{surveyTheme.numOccurrences > 1 ? "s" : ""}</b>.</span>;
    }
  }

  if (!surveyContext || !relatedResponses || !surveyTheme) {
    return <div className="m-5">Loading...</div>;
  }

  return (
    <>
      <Breadcrumb className="m-3">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/a/surveys`}}>Surveys</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/a/surveys/${surveyContext.id}`}}>{surveyContext.surveyName}</Breadcrumb.Item>
        <Breadcrumb.Item active><b>Theme: {abbreviate(surveyTheme.text, 40)}</b></Breadcrumb.Item>
      </Breadcrumb>

      <Container className='my-5'>
        <Row className="my-4 align-items-start">
          <Col className="fw-bold text-end col-2">Theme</Col>
          <Col>
            <table className="table table-bordered mb-0">
              <tbody>
                <tr style={{ backgroundColor: getRowBgColorAndIcon(surveyTheme.sentiment)[0] }}>
                  <td className="py-1">
                    {surveyTheme.text}&nbsp;&nbsp;
                    {getRowBgColorAndIcon(surveyTheme.sentiment)[1]}
                    {surveyTheme.hidden && (
                      <em>&nbsp;&nbsp;(hidden)</em>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <span className="small">{getNumOccurrencesText()}</span>
          </Col>
        </Row>
        <Row className="my-4 align-items-start">
          <Col className="fw-bold text-end col-2">{comments.length === 0 ? "Add a comment" : "Your comments about this theme"}</Col>
          <Col>
            <ListGroup>
              <ListGroup.Item className="p-2">
                <Form onSubmit={addComment}>
                  <Form.Group className='m-0'>
                    <Stack direction="horizontal" gap={3}>
                      <input
                        type="text"
                        className="form-control"
                        id="newComment"
                        name="newComment"
                        placeholder="We've made new improvements to..."
                        value={newComment}
                        onChange={handleNewCommentChange}
                        required
                        maxLength="150"
                        disabled={changingComments}
                      />
                      <button type="submit" className="btn btn-primary ms-auto me-2" disabled={changingComments}>
                        Add
                      </button>
                    </Stack>
                  </Form.Group>
                </Form>
                {commentError && <div className="alert alert-danger mt-3" role="alert">{commentError}</div>}
              </ListGroup.Item>
              {comments.map((comment, index) => (
                <ListGroup.Item key={index} className="py-1 px-2">
                  <Stack direction="horizontal" gap={3}>
                    <div>
                      {comment.text.split('\n').map((item, key) => {
                        return <span key={key}>{item}<br /></span>
                      })}
                    </div>
                    <Button className="ms-auto" variant="link" size="sm" onClick={() => {deleteComment(index)}}>
                      <IoTrash className="text-danger mb-1" />
                    </Button>
                  </Stack>
                  <span className="small text-muted">{new Date(comment.createdAt).toLocaleString()}</span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
        <Row className="my-4 pb-5 align-items-start">
          <Col className="fw-bold text-end col-2">Responses linked to this theme</Col>
          <Col>
            <table className="table">
              <tbody>
                {relatedResponses.items.map((response, index) => (
                  <tr key={index} className="align-items-center">
                    <td className="p-1">
                      {/* This uses createdAt (rather than processedAt) because response is a SurveyResponsePoints, not a SurveyResponse */}
                      <span>{new Date(response.createdAt).toLocaleString()}</span>
                    </td>
                    <td className="p-1">
                      <Link to={`/a/surveys/${surveyContext.id}/responses/${response.surveyResponseID}`}>"{response.responseExcerpt}"</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {(!relatedResponses.isFirstPage || relatedResponses.nextToken !== null) && (
              <div className="d-flex justify-content-end">
                <ButtonGroup size="">
                  <Button variant="secondary" disabled={relatedResponses.isFirstPage} onClick={() => fetchResponsesForTheme(surveyTheme.id, null)}><IoPlayBack /></Button>
                  <Button variant="secondary" disabled={relatedResponses.nextToken === null} onClick={() => fetchResponsesForTheme(surveyTheme.id, relatedResponses.nextToken)}><IoCaretForward/></Button>
                </ButtonGroup>
              </div>
            )}
          </Col>
        </Row>

      </Container>
    </>
  );
}

export default SurveyThemeDetails;
